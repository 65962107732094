import { StyledEngineProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import App from "./App";
import Theme from "./components/Theme/Theme";
import "./index.css";
import initialstate from "./redux/store/initialstate.json";
import configureStore from "./redux/store/store";
import reportWebVitals from "./reportWebVitals";

import axios, { AxiosResponse } from "axios";
import { decryptData, encryptData } from "./components/helpers/helperFunctions";
const envTypes = ["prod", "test"];
const store = configureStore(initialstate);
const k = `V!z!0n$y$&$erv0deskHelpDe$k@pp20`;
export const getLocalKey = async () => {
  let b = await decryptData(`${process.env.REACT_APP_SERVODESK_APP_KEY}`, k)
    .then((val) => {
      return val;
    })
    .catch((err) => {
      return "";
    });
  return b || "";
};
//axios.defaults.baseURL='https://localhost:7062/';
//axios.defaults.headers.common['Authorization']='Auth Token';
axios.interceptors.request.use(async (request: any) => {
  // console.log("request", request);
  let token = localStorage.getItem("loginInfo");
  let tenantCode = localStorage.getItem("tenantCode");
  if (token !== null) {
    token = JSON.parse(token).token;
    request.headers.Authorization = `Bearer ${token}`;
  }
  if (tenantCode !== null) {
    request.headers["tenant-code"] = tenantCode;
    request.headers["app-type"] = "Web";
  }

  /*===== ENCRYPTION =====*/
  if (
    !request.url.includes("/Files?") &&
    envTypes.includes(`${process.env.REACT_APP_ENV}`) &&
    (request.method === "post" || request.method === "put")
  ) {
    // console.log("envTypes_request", request.data, request);
    const getLocalKey1 = await getLocalKey().then((x) => x);
    const safeData = await encryptData(
      JSON.stringify(request.data),
      `${getLocalKey1}`
    )
      .then((val) => {
        request.headers["Content-Type"] = "text/plain";
        request.data = val;
      })
      .catch((err) => {});
  }

  if (!request.data) return request;

  // request.data.profilePicturePath = request.data.profilePicturePath || "/";
  // request.data.agentAvatarPath = request.data.agentAvatarPath || "/";
  // request.data.attachmentPath = request.data.attachmentPath || "/";
  if (request?.data?.assetAttachmentsList) {
    request.data.assetAttachmentsList = request?.data?.assetAttachmentsList.map(
      (i: any) => {
        return { ...i, attachmentPath: i.attachmentPath || "/" };
      }
    );
  }
  if (request?.data?.ticketLogs?.attachment) {
    request.data.ticketLogs.attachment = request.data.ticketLogs.attachment.map(
      (i: any) => {
        return { attachmentId: i.id, attachmentPath: i.attachmentPath || "/" };
      }
    );
    request.data.ticketLogs.ticketLogAttachmentsList =
      request.data.ticketLogs.attachment;
  }
  return request;
});

// axios.interceptors.response.use((response: AxiosResponse) => {
//   console.log("interceptors_response", response)

//   if (response.status == 200 || response.status == 201) {
//     return response;
//   } else if(response.status == 401) {
//     store.dispatch(DoLogOutAction());
//   }
// })

axios.interceptors.response.use(
  async (response: AxiosResponse) => {
    if (
      envTypes.includes(`${process.env.REACT_APP_ENV}`) &&
      response.data &&
      typeof response.data === "string"
    ) {
      const getLocalKey1 = await getLocalKey().then((x) => x);
      const safeData = await decryptData(response.data, `${getLocalKey1}`);
      try {
        response.data = JSON.parse(safeData);
      } catch {
        response.data = safeData;
      }
    }
    return response;
  },
  async (error) => {
    // console.log("error_response", error?.response);
    if (
      envTypes.includes(`${process.env.REACT_APP_ENV}`) &&
      error?.response?.data &&
      typeof error?.response?.data === "string"
    ) {
      const getLocalKey1 = await getLocalKey().then((x) => x);
      const safeData = await decryptData(
        error?.response?.data,
        `${getLocalKey1}`
      )
        .then((val) => {
          error.response.data = val;
        })
        .catch((err) => {});
    }
    if (error?.response?.status === 401) {
      // console.log("404", error.response, error.response.status);
      localStorage.clear();
      store.dispatch({
        type: "LOGIN_CALL",
        payload: false,
      });

      // window.location.href = location.origin;
      //.dispatch(DoLogOutAction());
      // store.dispatch({
      //   type: ActionType.SNACKBAR,
      //   payload: {
      //     message: "Something happened!!!.",
      //     severity: "error",
      //     open: true,
      //   },
      // });
      return Promise.reject(error.response);
    }
    return Promise.reject(error.response);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <Theme>
          <StyledEngineProvider injectFirst>
            <App />
          </StyledEngineProvider>
        </Theme>
      </HashRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
