import React, { createRef, useEffect, useRef, useState } from "react";
import {
  CommonDataForFormDataProperties,
  NullableArgument,
  ValidationResultS,
  iFormData,
  iMemoizedComponent,
  iPermissions,
  iTicketManagementProperties,
  iUserFormData,
  iUserFormDataPayload,
} from "./Type";
import { isValidate } from "./Validation";
import { Box, Grid } from "@mui/material";
import CustomSelect from "../../../components/common/customSelect/CustomSelect";
import BasicButton from "../../../components/common/button/BasicButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CustomFormLabel from "../../../components/common/form-label/CustomFormLabel ";
import CustomInput from "../../../components/common/textField/CustomInput";
import CustomDatePicker from "../../../components/common/date-picker/CustomDatePicker";
import CustomCreatable from "../../../components/common/customSelect/CustomCreate";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import RightSectionLayout from "./RightPanel";
import AddContact from "../AddContact";
import AddContactCopy from "../AddContactCopy";
import AddAsset from "../AddAsset";
import {
  schemaForTicketCreationFormData,
  schemaForTicketCreationFormDataWithoutDueDate,
} from "./Schema";
import CustomChip from "../../../components/common/chip/CustomChip";
import AddBoxRoundedIcon from "@mui/icons-material/AddBoxRounded";
import TicketAddUpdatePopup from "../../../components/TicketManagementSystem/Modules/AddUpdatePopup";
import { useDispatch, useSelector } from "react-redux";
import { getDateRecom } from "../../../redux/actions/SystemManagement/AddPriority";
import moment from "moment";
import { SnackBar } from "../../../services/AssetsManagement/AssetType";
import { IState } from "../../../redux/reducers/rootReducers";
import { getCategory } from "../../../redux/actions/SystemManagement/AddCategory";
import { useNavigate } from "react-router-dom";
import useGetQueryParams from "../../../hooks/useGetQueryParams";
import { fileUploadService } from "../../../services/fileUpload/FileUpload";
import { classNames } from "react-select/dist/declarations/src/utils";
import SendEmailPopup from "../SendEmailPopup";
import { FindItem } from "./Edit";
import AsyncDropDown from "./AsyncDrodown/AsyncDropDown";
import configs from "../../../configs/config";
import axios from "axios";
import { ActionType } from "../../../redux/actions/dashboard";
import { ConvertZWithoutFormat } from "../../../components/helpers/helperFunctions";
import AISuggestions from "../../../components/ChatBot/AI/AISuggestions";
import { TextBoxLatest } from "../../../components/common/TextBoxLatest/TextBoxLatest";
import CategoryTreeSelection from "../AddCategoryPopup";
import { triggerAppAlert } from "../../../redux/actions/SelfServicePortal/ContactAid/ContactAid";
import { v4 as uuidv4 } from 'uuid';

export const generateErrorList = (
  isValid: boolean,
  error: Record<string, string[]>,
  field: string
) => {
  if (!isValid && field in error && error[field].length > 0) {
    return (
      <>
        {error[field].map((item: string) => {
          return (
            <>
              <div style={{ color: "red", fontSize: "12px" }}>{item}</div>
            </>
          );
        })}
      </>
    );
  }
};

function truncateString(str: string) {
  if (str.length > 5) {
    return str.substring(0, 8) + "...";
  }
  return str;
}

export const generateTagFromEdit = (tags: any) => {
  return tags?.map((i: any) => {
    const tempObj = { ...i, label: i.name };
    return tempObj;
  });
};

export const getBase64 = async (file: any, cb: any) => {
  let reader: any = new FileReader();
  let baseVal: any = "";
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result, file);
  };
  reader.onerror = function (error: any) {
    console.log("Error: ", error);
  };
  return baseVal;
};

export const generateIdFromObject = (
  value: NullableArgument<CommonDataForFormDataProperties>
) => {
  if (value) {
    return value.id;
  } else {
    return null;
  }
};

export const generateIdFromObjectForTicketCreation = (
  value: NullableArgument<CommonDataForFormDataProperties>
) => {
  if (value) {
    return value.id;
  } else {
    return 0;
  }
};

const generateFormFieldOrderForDesktop = (
  type: any,
  permissions: iPermissions
): number => {
  let order: number = 0;
  if (permissions.isSuperAdmin || permissions.assetAdminAccess) {
    if (type == "source") {
      order = 7;
    } else if (type == "priority") {
      order = 6;
    } else if (type == "openDate") {
      order = 9;
    } else if (type == "subject") {
      order = 8;
    } else if (type == "dueDate") {
      order = 11;
    } else if (type == "desc") {
      order = 10;
    } else if (type == "nextActionDate") {
      order = 13;
    } else if (type == "nextActionDesc") {
      order = 14;
    } else if (type == "tag") {
      order = 12;
    }
  } else {
    if (type == "source") {
      order = 5;
    } else if (type == "priority") {
      order = 6;
    } else if (type == "openDate") {
      order = 7;
    } else if (type == "subject") {
      order = 8;
    } else if (type == "dueDate") {
      order = 9;
    } else if (type == "desc") {
      order = 10;
    } else if (type == "nextActionDate") {
      order = 11;
    } else if (type == "nextActionDesc") {
      order = 12;
    } else if (type == "tag") {
      order = 14;
    }
  }
  return order;
};

const generateAssetField = (
  userFormData: iUserFormData,
  permissions: iPermissions,
  setIsOpenAsset: any
) => {
    return (
      <MemoizedComponent userFormData={userFormData} type="asset">
        <Grid className="tickettype-assest" item md={1}>
          <CustomFormLabel labelName={"Asset"} isMandotary={false} />
          <CustomInput
            readOnly={true}
            iconPosition={"start"}
            icon={
              userFormData?.asset?.length && userFormData.asset.length > 0 ? (
                <CustomChip
                  icon={false}
                  label={userFormData?.asset?.length || "0"}
                  color="#2F80ED"
                  bg="#e3efff"
                  variant={"none"}
                  transform={""}
                />
              ) : (
                <AddBoxRoundedIcon
                  className="add-asset-icon"
                  sx={{ color: "#0A93FC" }}
                />
              )
            }
            isError={false}
            label={"Asset"}
            name="asset"
            value={""}
            onClick={() => {
              if(permissions.isSuperAdmin || permissions.assetAdminAccess){
                setIsOpenAsset(true);
              }
            }}
            onChange={() => {}}
            helperText={""}
            placeholder={
              userFormData?.asset?.length > 0
                ? "Select more assets"
                : "Click here to select an asset"
            }
            isDisabled={(permissions.isSuperAdmin || permissions.assetAdminAccess) ? false : true}
          />
          {" "}
          {(permissions.isSuperAdmin || permissions.assetAdminAccess) ? <></> :
            <span style={{ fontSize: "12px", color: "#c8c8c8" }}>* Choosing Assets to the ticket is disabled in your role</span>
          }
        </Grid>
      </MemoizedComponent>
    );
};

const generateAddContactPopup = (
  isOpenAddContact: any,
  setIsOpenAddContact: any,
  onContactSubmit: any,
  OrgList: any,
  style: any
) => {
  if (isOpenAddContact) {
    return (
      <AddContactCopy
        setIsOpenAddContact={setIsOpenAddContact}
        isOpenAddContact={isOpenAddContact}
        onContactSubmit={onContactSubmit}
        orgList={OrgList}
        style={style}
      />
    );
  }
};
const generateAddAssetPopup = (
  isOpenAsset: any,
  setIsOpenAsset: any,
  userFormData: any,
  onAssetSelected: any
) => {
  if (isOpenAsset) {
    return (
      <AddAsset
        setIsOpenAsset={setIsOpenAsset}
        selected={userFormData.asset}
        isOpenAsset={isOpenAsset}
        defaultContactFilter={userFormData?.primaryContact?.name}
        onAssetSelected={onAssetSelected}
      />
    );
  }
};

const generateAddUpdatePopup = (respData:any = null) => {
  return <TicketAddUpdatePopup respData = {respData} />;
};

const generateSendEmailPopup = (
  userFormData: any,
  submittedData: any,
  isSendEmailPopup: any,
  setIsSendEMailPopup?: any,
  filterEmailData?: any
) => {
  if (isSendEmailPopup) {
    return (
      <SendEmailPopup
        setEditData={false}
        filterEmailData={filterEmailData}
        type="add"
        isSendEmailPopup={isSendEmailPopup}
        setIsSendEMailPopup={setIsSendEMailPopup}
        submittedData={{
          ...submittedData,
          contactList: userFormData.contactList,
        }}
        setData={false}
      />
    );
  }
};



const restrictWhiteSpaceFromTag = (
  e: any,
  handleSelectChange: (e: any, type: string) => void
) => {
  if (e && e?.[e?.length - 1]?.label && e[e.length - 1].label.trim() === "") {
    return null;
  } else {
    handleSelectChange(e, "tag");
  }
};

// This optimization prevents unnecessary re-renders by memoizing children.

const MemoizedComponent: React.FC<iMemoizedComponent> = ({
  userFormData,
  type,
  children,
  contacts = null,
  secondaryOrPrmaryContact = null,
}) => {
  return React.useMemo(() => {
    return children;
  }, [userFormData[type], contacts, secondaryOrPrmaryContact]);
};

const generateUniqueContcats = (contacts: any, contact: any) => {
  console.log("generateUniqueContcats", contact);
  if (contact) {
    if (contact.id) {
      let newArr: any = contacts.filter((x: any) => x.id != contact.id);
      return newArr;
    } else {
      return contacts;
    }
  } else {
    return contacts;
  }
};

const getDateRecomFun = async (id: any) => {
  let res = await getDateRecom(id);
  return res;
};

const modifyTag = (tags: any) => {
  console.log("validations ------------", tags);

  if (tags?.length >= 0) {
    let temp = tags?.map((item: any) => {
      let tempObj = {
        name: item?.label,
      };
      return tempObj;
    });
    return temp;
  } else {
    return null;
  }
};

const generateSouceName = (source: any) => {
  if (source) {
    if (source.code) {
      return source.code;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

const modifyAsset = (asset: any) => {
  console.log("asset view", asset);
  let tempAsset: any = [];
  asset.filter((item: any) => {
    if (item?.ticketId) {
      tempAsset.push(item);
    } else {
      tempAsset.push({
        assetId: item?.id,
      });
    }
  });
  console.log("asset view", tempAsset);

  return tempAsset;
};

// Form is rendered here

const Form = ({
  ticketManagementProperties,
  permissions,
  style,
  defaultValue,
  id,
  pageTitle,
  actionType,
  respData = null,
}: {
  ticketManagementProperties: iTicketManagementProperties;
  permissions: iPermissions;
  style: any;
  defaultValue: iUserFormData;
  id?: any;
  pageTitle: string;
  actionType: "Add" | "Edit";
  respData:any;
}) => {
  const dispatch = useDispatch();
  const titleRef: any = createRef();
  const descRef: any = createRef();
  const [error, setError] = React.useState<ValidationResultS>({
    isValid: false,
    errors: {},
  });
  const sendEmailPreview = useSelector(
    (state: IState) => state?.TicketViewReducer?.sendEmailView
  );
  const Category = useSelector(
    (state: IState) => state?.categoryReducer?.Data?.data
  );

  const [isSendEmailPopup, setIsSendEMailPopup] =
    React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [contacts, setContacts] = React.useState<any>([]);
  const [priority, setPriority] = React.useState<any>(
    ticketManagementProperties.priorities
  );
  const [ticketType, setTicketType] = React.useState<any>(
    ticketManagementProperties.TicketTypes
  );
  const [catTree, setCatTree] = React.useState<any>([]);

  const [isOpenAddContact, setIsOpenAddContact] = React.useState(false);
  const [isOpenAsset, setIsOpenAsset] = React.useState(false);
  const [isShowMandotaryIconForDueDate, setIsShowMandotaryIconForDueDate] =
    React.useState(true);

  const [userFormData, setUserFormData] =
    React.useState<iUserFormData>(defaultValue);

  const navigate = useNavigate();  
  const isClone: string | boolean = useGetQueryParams("clone");
  const lastEventRef = useRef<any>(null);

  const [filterEmailData, setFilterEmailData] = useState({
    cc: [],
    selectedCC: [],
    bcc: [],
    selectedBCC: [],
    to: [],
    selectedTo: [],
  });

  useEffect(() => {
    handleSelectChange(defaultValue.category, "category");
  }, [defaultValue.category]);

  useEffect(() => {
    console.log("cat show in form", defaultValue);
    if (titleRef?.current) {
      titleRef.current.value = defaultValue?.subject || "";
    }
    if (descRef?.current) {
      descRef.current.value = defaultValue?.desc || "";
    }
  }, [defaultValue]);

  useEffect(() => {
    console.info("sendEmailPreview", sendEmailPreview);
    if (sendEmailPreview) {
      setIsSendEMailPopup(true);
    } else {
      setIsSendEMailPopup(false);
    }
  }, [sendEmailPreview]);

  useEffect(() => {
    console.info(
      "LATEST TICKET----------TICKET MANAGEMENT PROPERTIES",
      ticketManagementProperties
    );
    if (ticketManagementProperties?.contacts?.length > 0) {
      let contacts: any = [];

      ticketManagementProperties.contacts.filter((contact) => {
        let modifiedContact = {
          ...contact,
          icon: (
            <AccountCircleOutlinedIcon sx={{ color: "rgb(10, 147, 252)" }} />
          ),
          iconRight: contact.isVIP ? (
            <DiamondOutlinedIcon
              fontSize="small"
              color="primary"
              width="15px"
            />
          ) : (
            false
          ),
        };
        contacts.push(modifiedContact);
      });
      if (contacts.length > 0) {
        setContacts(contacts);
      }
    }
  }, [ticketManagementProperties.contacts]);

  console.log("priorityIdFromUrlpriorityIdFromUrl", isClone);


  // useEffect(() => {
  //   if (primaryContactFromUrl) {
  //     let contact = JSON.parse(primaryContactFromUrl)

  //     let temp = contact
  //     temp !== undefined && handleSelectChange(temp, "primaryContact");
  //   }
  //   if (secondaryContactFromUrl) {
  //     let contact = JSON.parse(secondaryContactFromUrl)
  //     let temp = contact
  //     temp !== undefined && handleSelectChange(temp, "secondaryContact");
  //   }
  // }, [primaryContactFromUrl, contacts, secondaryContactFromUrl]);

  // useEffect(() => {
  //   if (ticketTypeFromUrl) {
  //     let temp = FindItem(
  //       ticketManagementProperties.TicketTypes,
  //       ticketTypeFromUrl
  //     );
  //     temp !== undefined && handleSelectChange(temp, "ticketType");
  //   }
  // }, [ticketTypeFromUrl, ticketManagementProperties.TicketTypes]);



  // useEffect(() => {
  //   if (subjectFromUrl) {
  //     let temp = subjectFromUrl;
  //     temp && handleSelectChange(temp, "subject");
  //   }
  //   if (descFromUrl) {
  //     let temp = descFromUrl;
  //     temp && handleSelectChange(temp, "desc");
  //   }
  // }, [subjectFromUrl, descFromUrl]);

  

  const promiseOptions = (inputValue: any, callback: any) => {
    if (inputValue.length > 2) {
      axios
        .get(
          `${configs.BASE_URL}/Contacts?search=${inputValue}&page=1&count=50&isActive=true`
        )
        .then((response: any) => {
          const data = response.data;
          console.error("options", data);
          const options = data?.map((item: any) => ({
            label: `${item.name},(${item.email})`,
            name: item.name,
            value: item.name,
            id: item.id,
            knownAs: item.knownAs,
            userName: item.userName,
            jobTitle: item.jobTitle,
            email: item.email,
            whatsAppNumber: item.whatsAppNumber,
            company: item.companyName,
            place: item.officeLocation,
            no: item.mobilePhone,
            isVIP: item.isVIP,
            alert: item.alert,
            profilePictureId: item.profilePictureId,
          }));
          callback(options); // Pass options to the callback
        })
        .catch((error: any) => {
          console.error("Error fetching data:", error);
          callback([]); // Pass options to the callback
          dispatch({
            type: ActionType.SNACKBAR,
            payload: {
              message: `Server Error! Log out and try again`,
              severity: "error",
              open: true,
            },
          });
        });
    } else {
      callback([]); // Pass options to the callback
    }
  };

  const handleSelectChange = (e: unknown, type: string) => {
    setUserFormData((prevUserFormData) => {
      return {
        ...prevUserFormData,
        [type]: e,
      };
    });
  };

  useEffect(() => {
    if (id) {
      prefillCategoryTree(defaultValue.ticketType);
    }
  }, [Category]);

  const imgCallBack = async (params: any, file: any) => {
    // let fileObject = {
    //   description: file.name,
    //   filetypeid: 1,
    //   formFile: file,
    // };
    // let fileUpload: any = await fileUploadService(
    //   fileObject,
    //   () => {},
    //   () => {}
    // );
    // if (fileUpload.status == 201) {
    let recs = userFormData.attachment;
    recs.push({
      file: params,
      fileName: file.name,
      Type: file.type,
      formFile: file,
      isActive: true,
      id: uuidv4(),
      
    });
    handleSelectChange([...recs], "attachment");
   console.log("imgCallBackfile PARAMS", file)
  };

  const handleSelectChangeForFilter = (e: any, type: string) => {
    const splitCC = e?.email?.split(";");

    console.log("handleSelectChangeForFilter", e?.email);

    let temp: any = [];

    if (e?.email) {
      splitCC?.filter((ele: any) => {
        let a: any = {
          label: ele,
          name: ele,
          value: ele,
          id: ele,
          color: "red",
        };
        temp.push(a);
      });
    }

    setFilterEmailData((prevUserFormData: any) => {
      return {
        ...prevUserFormData,
        [type]: temp,
      };
    });
  };

  const onSubmit = (event: any) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    const data: iFormData = {};

    formData.forEach((value, key) => {
      data[key] = value.toString();
    });

    setLoading(true);

    let validationData: iUserFormDataPayload = {
      contactId: generateIdFromObjectForTicketCreation(
        userFormData.primaryContact
      ),
      secondaryContactId: generateIdFromObjectForTicketCreation(
        userFormData.secondaryContact
      ),
      priorityId: generateIdFromObjectForTicketCreation(userFormData.priority),
      title: data.subject,
      description: data.desc,
      ticketTypeId: generateIdFromObjectForTicketCreation(
        userFormData.ticketType
      ),
      dueDateTime: userFormData.dueDate
        ? moment.utc(userFormData.dueDate)?.toISOString()
        : null,
      sourceCode: generateSouceName(userFormData.source),
      nextActionDescription: data.nextActionDesc,
      firstResponseDueDateTime: moment
        .utc(userFormData.nextActionDate)
        .toISOString(),
      openDate:  isClone ?  moment(new Date()).toISOString()  : moment.utc(userFormData.openDate).toISOString(),
      categoryId: userFormData?.category ? userFormData?.category.id : null,
    };

    const validation = isValidate(
      validationData,
      isShowMandotaryIconForDueDate
        ? schemaForTicketCreationFormData
        : schemaForTicketCreationFormDataWithoutDueDate
    );

    console.log("userFormDatauserFormData", userFormData);

    if (validation.isValid) {
      setLoading(false);
      const filteredArrayForTo = filterEmailData?.selectedTo?.filter(
        (item: any) => item.label !== undefined
      );
      const filteredArrayForCC = filterEmailData?.selectedCC?.filter(
        (item: any) => item.label !== undefined
      );
      const filteredArrayForBCC = filterEmailData?.selectedBCC?.filter(
        (item: any) => item.label !== undefined
      );

      const StringTO = filteredArrayForTo
        ?.map((item: any) => item.label)
        .join(";");
      const StringCC = filteredArrayForCC
        ?.map((item: any) => item.label)
        .join(";");
      const StringBCC = filteredArrayForBCC
        ?.map((item: any) => item.label)
        .join(";");

        const filteredAttachments = isClone ? userFormData.attachment?.map(({ id, ...rest }:any) => rest) : userFormData.attachment

      // Form is valid, proceed with submission
      openModal({
        id:  isClone ? "" : id,
        sourceCode: generateSouceName(userFormData.source),
        ticketLogs: {
          ...validationData,
          ticketLogChangesList: isClone ? [
            {
                "changes": `This ticket has been cloned from Ticket ID:${id}`
            }
        ] : [],
          priority: userFormData.priority,
          source: userFormData.source,
          ticketType: userFormData.ticketType,
          contacts: userFormData.primaryContact,
          secondaryContacts: userFormData.secondaryContact,
          groupId: userFormData.groupId,
          agentId: userFormData.agentId,
          statusId: userFormData.statusId,
          isAISuggested: userFormData?.isAISuggested,
          note: userFormData.note,
          effort: userFormData.effort,
          ticketLogAttachmentsList:  filteredAttachments,
          circulationListTO:
            filterEmailData?.selectedTo.length > 0 ? StringTO : null,
          circulationListBCC:
            filterEmailData?.selectedBCC.length > 0 ? StringBCC : null,
          circulationListCC:
            filterEmailData?.selectedCC.length > 0 ? StringCC : null,
        },
        ticketAssetList:
          userFormData.asset.length > 0 ? modifyAsset(userFormData.asset) : [],
        ticketRelationshipList: null,
        ticketTagList: modifyTag(userFormData.tag),
        ticketTaskList: [],
        totalEffort: userFormData.totalEffort,
        type: "addOrEdit",
      });
      setError({ isValid: false, errors: {} });
    } else {
      // Form is invalid, update the errors state

      setError(validation);

      setLoading(false);
    }
  };

  const onContactSubmit = (res: any) => {
    const newArrayWithUpdatedContactList = [res, ...contacts];
    setContacts(newArrayWithUpdatedContactList);
    handleSelectChange(res, "primaryContact");
  };

  const onCancelBtnClick = () => {
    navigate(`/TicketManagement`);
  };

  const onAssetSelected = (res: any) => {
    console.log("asset selected", res);
    setUserFormData((prevUserFormData) => {
      return {
        ...prevUserFormData,
        asset: res,
      };
    });
  };

  const prefillTheNextActionDateAndDueDate = (priority: any) => {
    if (priority) {
      let dateRecomendation = getDateRecomFun(priority.id);
      dateRecomendation.then((res: any) => {
        if (res) {
          let dueDateInUtc: any = moment.utc(res.dueDate).local();

          let nextActionDateInUtc: any = moment.utc(res.nextActionDate).local();
          if (priority?.isResolutionTimeNoSLA) {
            setIsShowMandotaryIconForDueDate(false);
          } else if (
            priority?.isResolutionTimeCustomDueDate ||
            priority?.isResponseTimeSLATiming
          ) {
            setIsShowMandotaryIconForDueDate(true);
          }

          if (
            (priority.isResolutionTimeNoSLA ||
              priority.isResolutionTimeCustomDueDate) &&
            (priority.isResponseTimeNoSLA ||
              priority.isResponseTimeCustomDueDate)
          ) {
            handleSelectChange(null, "nextActionDate");
            handleSelectChange(null, "dueDate");
          } else if (
            priority.isResolutionTimeNoSLA ||
            priority.isResolutionTimeCustomDueDate
          ) {
            handleSelectChange(nextActionDateInUtc, "nextActionDate");
            handleSelectChange(null, "dueDate");
          } else if (
            priority.isResponseTimeNoSLA ||
            priority.isResponseTimeCustomDueDate
          ) {
            handleSelectChange(null, "nextActionDate");
            handleSelectChange(dueDateInUtc, "dueDate");
          } else {
            handleSelectChange(nextActionDateInUtc, "nextActionDate");
            handleSelectChange(dueDateInUtc, "dueDate");
          }
        } else {
          handleSelectChange(null, "dueDate");
          handleSelectChange(null, "nextActionDate");
          dispatch(
            SnackBar(
              `SLA retrieval failed, Please retry later or contact our support team`,
              "error"
            )
          );
        }
      });
    }
  };

  const prefillPriorityBasedOnTicketType = (ticketType: any) => {
    if (ticketType) {
      let updatedPriority: any = [];
      ticketManagementProperties.priorities.filter((item: any) => {
        item?.ticketTypeList.filter((ele: any) => {
          if (ele?.ticketType?.id == ticketType?.id) {
            updatedPriority.push(item);
          }
        });
      });
      setPriority(updatedPriority);
    } else {
      setPriority(ticketManagementProperties.priorities);
    }
  };
  const prefillTicketTypeBasedOnPriority = (priority: any) => {
    console.log(
      "prefillTicketTypeBasedOnPriority",
      priority,
      ticketManagementProperties.TicketTypes
    );
    if (priority) {
      let updatedTicketType: any = [];
      ticketManagementProperties.TicketTypes.filter((ticketTypeItem) => {
        priority.ticketTypeList.filter((ele: any) => {
          if (ele?.ticketType?.id == ticketTypeItem?.id) {
            updatedTicketType.push(ticketTypeItem);
          }
        });
      });
      setTicketType(updatedTicketType);
    } else {
      setTicketType(ticketManagementProperties.TicketTypes);
    }
  };

  const prefillCategoryTree = (ticketType: any) => {
    return;
    if (ticketType) {
      console.log("prefillCategoryTree", Category, ticketType);
      let temp = Category?.filter((category: any) => {
        return (
          category.categoryTicketTypeList[0]?.ticketTypeId == ticketType.id
        );
      });
      setCatTree(temp);
    } else {
      setCatTree([]);
    }
  };

  const openModal = (payload: any) => {
    dispatch({
      type: "ADD_UPDATE_POPUP_INFO",
      payload: payload,
    });
  };

  const onRemoveAsset = (res: any) => {
    setUserFormData((prevUserFormData) => {
      return {
        ...prevUserFormData,
        asset: res,
      };
    });
  };

  const onRemoveAttchment = (id: number) => {
    let allRecs = [...userFormData.attachment]; // Shallow copy of the array
    let attachment = allRecs.find((rec) => rec.id === id); // Find the attachment by id

    if (attachment) {
      attachment.isActive = false; // Set isActive to false if found
      handleSelectChange(allRecs, "attachment"); // Update the attachment list
    }
  };

  const onCategorySubmit = (res: any) => {
    handleSelectChange(res, "category");
  };
  return (
    <Box className="pl-10p">
      {generateAddContactPopup(
        isOpenAddContact,
        setIsOpenAddContact,
        onContactSubmit,
        ticketManagementProperties.OrgList,
        style
      )}

      {generateAddAssetPopup(
        isOpenAsset,
        setIsOpenAsset,
        userFormData,
        onAssetSelected
      )}

      <CategoryTreeSelection callback={onCategorySubmit}/>

      {generateAddUpdatePopup(respData)}
      {generateSendEmailPopup(
        userFormData,
        sendEmailPreview,
        isSendEmailPopup,
        setIsSendEMailPopup,
        filterEmailData
      )}
      <Box>
        <Grid container columnSpacing={5}>
          <Grid item md={5}>
            <div
              style={{
                color: "#273A6B",
                fontSize: "22px",
                fontWeight: 900,
                marginTop: "7px",
                marginBottom: "15px",
              }}
              className={`${style.heading} tickets-heading`}
            >
              {pageTitle}{" "}
            </div>
          </Grid>

          <Grid item md={12}>
            <Box className="pb-1">
              <AISuggestions
                defaultVal={defaultValue?.subject || ""}
                callback={(val: any) => {
                  // We will use if to check the ticket type present in the dropdown and if so
                  // will use the same
                  let ticketTypeSuggestion = FindItem(
                    ticketType,
                    val?.ticketType?.id
                  );

                  let prioritySuggestion = FindItem(
                    priority,
                    val?.priority?.id
                  );

                  // Its used for filling the form
                  setUserFormData((prevUserFormData) => {
                    return {
                      ...prevUserFormData,
                      note: val?.note,
                      priority: prioritySuggestion,
                      ticketType: ticketTypeSuggestion,
                      status: val?.ticketStatus || null,
                      statusId: val?.ticketStatus?.id || null,
                      category: val?.category || { label: "", id: "" },
                      isAISuggested: true,
                    };
                  });
                  if (actionType === "Add") {
                    titleRef.current.value = val?.title;
                    descRef.current.value = val?.description;
                  }
                  prefillTheNextActionDateAndDueDate(prioritySuggestion);
                  //prefillCategoryTree(val?.ticketType);
                  //has context menu
                  descRef.current.focus();
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={`${style.wrapper} pb-2 add-ticket-items`}>
        <Box className={`${style.leftSection} pb-1`} id="new-tickets">
          <form onSubmit={onSubmit}>
            <Box className="p-1 white-bg borderRadius-10">
              <Grid
                container
                columnSpacing={5}
                rowSpacing={2}
                columns={{ xs: 1, sm: 1, md: 1 }}
              >
                <Grid item md={1}>
                  {/* <MemoizedComponent userFormData={userFormData} type="subject"> */}
                  {/* <CustomFormLabel labelName={"Subject"} isMandotary={true} /> */}

                  {/* <CustomInput isDisabled={false} readOnly={false} onClick={null} iconPosition={"start"} icon={false} isError={false}
                      label={"Enter the subject"} name="subject" value={userFormData.subject} // value={userFormData.subject}
                      helperText={""}
                      placeholder={"Enter the subject"}
                    /> */}
                  <TextBoxLatest
                    title="Subject"
                    isMandotary={true}
                    placeholder="Enter the subject"
                    ref={titleRef}
                    name="subject"
                  />

                  {/* </MemoizedComponent> */}
                  {generateErrorList(error.isValid, error.errors, "title")}
                </Grid>

                <Grid item md={1}>
                  {/* <MemoizedComponent userFormData={userFormData} type="desc">
                    <CustomFormLabel labelName={"Description"} isMandotary={true} />

                    <CustomInput isDisabled={false} readOnly={false} onClick={null} iconPosition={"start"} icon={false} isError={false}
                      label={"Enter the Description"} name="desc" defaultValue={userFormData.desc} // value={userFormData.desc}
                      helperText={""} placeholder={"Enter the Description"} />
                  </MemoizedComponent> */}
                  <TextBoxLatest
                    title="Description"
                    isMandotary={true}
                    placeholder="Enter the Description"
                    ref={descRef}
                    name="desc"
                    multiline={true}
                  />
                  {generateErrorList(
                    error.isValid,
                    error.errors,
                    "description"
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box className="p-1 mt-1 white-bg borderRadius-10">
              <Grid
                container
                columnSpacing={5}
                rowSpacing={2}
                columns={{ xs: 1, sm: 1, md: 2 }}
              >
                <Grid className="Add-Contacts" item md={1}>
                  <MemoizedComponent
                    userFormData={userFormData}
                    type="primaryContact"
                    contacts={contacts}
                    secondaryOrPrmaryContact={userFormData.secondaryContact}
                  >
                    <CustomFormLabel
                      labelName={"Primary contact"}
                      isMandotary={true}
                    />
                    <AsyncDropDown
                      promiseOptions={promiseOptions}
                      name="primaryContact"
                      placeHolder="Search and select a Primary contact"
                      handleChange={(e: any) => {
                        handleSelectChange(e, "primaryContact");
                        handleSelectChangeForFilter(e, "selectedTo");
                        console.log("primary contact", e.email);
                      }}
                      defaultValue={userFormData.primaryContact}
                      apiUrl={`${configs.BASE_URL}/Contacts`}
                    />
                  </MemoizedComponent>
                  <span style={{ fontSize: "12px", color: "#c8c8c8" }}>
                    * You need at least 3 characters to search
                  </span>
                  <span
                    className={`${style.AddContactLinkBtn} addcontactLinkbtn`}
                    onClick={() => {
                      setIsOpenAddContact(true);
                    }}
                  >
                    + Add contact
                  </span>

                  {generateErrorList(error.isValid, error.errors, "contactId")}
                </Grid>

                <Grid item md={1}>
                  <MemoizedComponent
                    userFormData={userFormData}
                    type="secondaryContact"
                    contacts={contacts}
                    secondaryOrPrmaryContact={userFormData.primaryContact}
                  >
                    <CustomFormLabel
                      labelName={"Secondary contact"}
                      isMandotary={false}
                    />
                    <AsyncDropDown
                      promiseOptions={promiseOptions}
                      name="secondaryContact"
                      placeHolder="Search and select a Secondary contact"
                      handleChange={(e: any) => {
                        handleSelectChange(e, "secondaryContact");
                        handleSelectChangeForFilter(e, "selectedCC");
                      }}
                      defaultValue={userFormData.secondaryContact}
                      apiUrl={`${configs.BASE_URL}/Contacts`}
                    />
                  </MemoizedComponent>
                  <span style={{ fontSize: "12px", color: "#c8c8c8" }}>
                    * You need at least 3 characters to search
                  </span>
                  {generateErrorList(
                    error.isValid,
                    error.errors,
                    "secondaryContactId"
                  )}
                </Grid>

                <Grid item md={1}>
                  {" "}
                  <CustomFormLabel
                    labelName={"Ticket types"}
                    isMandotary={true}
                  />
                  <CustomSelect
                    isOpen={false}
                    placeholder="Select a ticket type"
                    width={"100%"}
                    name="ticketType"
                    isColor={false}
                    isDisabled={false}
                    isError={false}
                    defaultValue={userFormData.ticketType}
                    options={ticketType}
                    handleChange={(e: any) => {
                      console.log("clear catrgoyr and ticket type", e);
                      if (e == null) {
                        handleSelectChange(null, "priority");
                        setCatTree([]);
                        handleSelectChange(
                          {
                            label: "",
                            id: "",
                          },
                          "category"
                        );
                      }
                      handleSelectChange(e, "ticketType");
                      prefillPriorityBasedOnTicketType(e);
                      // prefillCategoryTree(e);
                    }}
                    isMulti={false}
                  />
                  {generateErrorList(
                    error.isValid,
                    error.errors,
                    "ticketTypeId"
                  )}
                </Grid>

                {generateAssetField(userFormData, permissions, setIsOpenAsset)}

                <Grid item md={1}>
                  <CustomFormLabel labelName={"Category"} isMandotary={true} />
                  {/* <MemoizedComponent userFormData={userFormData} type="category"> */}
                  <CustomInput
                    isDisabled={false}
                    readOnly={true}
                    iconPosition={"start"}
                    icon={false}
                    isError={false}
                    label={"Category"}
                    name="category"
                    value={userFormData?.category?.label}
                    onClick={() => {
                      if(userFormData?.ticketType?.id){
                        dispatch({
                          type:"CATEGORY_POPUP_TICKET_TYPE_ID",
                          payload : userFormData?.ticketType?.id
                        })
                      }else{
                        dispatch(triggerAppAlert("Please choose Ticket type before selecting Category","warning"))
                      }
                    }}
                    onChange={() => {}}
                    helperText={""}
                    placeholder={"Click here to select a Category"}
                  />
                  {/* </MemoizedComponent> */}
                  <span style={{ fontSize: "12px", color: "#c8c8c8" }}>
                    * Please select a Ticket Type before selecting a category.
                  </span>
                  {generateErrorList(error.isValid, error.errors, "categoryId")}
                </Grid>
                <Grid item md={1}>
                  <MemoizedComponent userFormData={userFormData} type="source">
                    <CustomFormLabel labelName={"Source"} isMandotary={true} />
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select a source"
                      width={"100%"}
                      name="source"
                      isColor={false}
                      isDisabled={false}
                      isError={false}
                      defaultValue={userFormData.source}
                      options={ticketManagementProperties.ticketSources}
                      handleChange={(e: any) => {
                        handleSelectChange(e, "source");
                      }}
                      isMulti={false}
                    />
                  </MemoizedComponent>
                  {generateErrorList(error.isValid, error.errors, "sourceCode")}
                </Grid>

                <Grid item md={1}>
                  <MemoizedComponent
                    userFormData={userFormData}
                    type="priority"
                    contacts={priority}
                  >
                    <CustomFormLabel
                      labelName={"Priority"}
                      isMandotary={true}
                    />
                    <CustomSelect
                      isOpen={false}
                      placeholder="Select a Priority"
                      width={"100%"}
                      name="priority"
                      isColor={true}
                      isDisabled={false}
                      isError={false}
                      defaultValue={userFormData.priority}
                      options={priority}
                      handleChange={(e: any) => {
                        const eventString = JSON.stringify(e);
                        // Check if the event is the same as the last one
                        if (lastEventRef.current !== eventString) {
                          lastEventRef.current = eventString;
                          handleSelectChange(e, "priority");
                          prefillTheNextActionDateAndDueDate(e);
                          prefillTicketTypeBasedOnPriority(e);
                        }
                      }}
                      isMulti={false}
                    />
                  </MemoizedComponent>
                  {generateErrorList(error.isValid, error.errors, "priorityId")}
                </Grid>

                <Grid className="ticket-dates" item md={1}>
                  <MemoizedComponent
                    userFormData={userFormData}
                    type="openDate"
                  >
                    <CustomFormLabel
                      labelName={"Open Date"}
                      isMandotary={true}
                    />

                    <CustomDatePicker
                      isDisabled={true}
                      onHandleChange={(e: any) => {
                        handleSelectChange(e, "openDate");
                      }}
                      value={isClone ?   moment(new Date()) :ConvertZWithoutFormat(userFormData.openDate)}
                      isError={false}
                      helperText={""}
                      name={"openDate"}
                    />
                  </MemoizedComponent>
                  {generateErrorList(error.isValid, error.errors, "openDate")}
                </Grid>

                <Grid className="new-ticket" item md={1}>
                  <MemoizedComponent userFormData={userFormData} type="tag">
                    <CustomFormLabel labelName={"Tags"} isMandotary={false} />
                    <CustomCreatable
                      name="tag"
                      options={[]}
                      defaultValue={userFormData.tag}
                      placeholder="Select a tag"
                      width={"100%"}
                      isMulti={true}
                      handleInputChange={(e: any) => {
                        restrictWhiteSpaceFromTag(e, handleSelectChange);
                      }}
                    />
                  </MemoizedComponent>
                  {generateErrorList(error.isValid, error.errors, "tag")}
                </Grid>

                <Grid
                  className="ticket-dates"
                  item
                  md={1}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <CustomFormLabel
                    labelName={"Due Date"}
                    isMandotary={isShowMandotaryIconForDueDate}
                  />
                  <MemoizedComponent userFormData={userFormData} type="dueDate">
                    <CustomDatePicker
                      isDisabled={false}
                      onHandleChange={(e: any) => {
                        handleSelectChange(e, "dueDate");
                      }}
                      value={ConvertZWithoutFormat(userFormData.dueDate)}
                      isError={false}
                      helperText={""}
                      name={"dueDate"}
                    />
                  </MemoizedComponent>
                  {generateErrorList(
                    error.isValid,
                    error.errors,
                    "dueDateTime"
                  )}
                </Grid>

                <Grid
                  className="ticket-dates"
                  item
                  md={1}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <MemoizedComponent
                    userFormData={userFormData}
                    type="nextActionDate"
                  >
                    <CustomFormLabel
                      labelName={"First action Date"}
                      isMandotary={false}
                    />

                    <CustomDatePicker
                      isDisabled={false}
                      onHandleChange={(e: any) => {
                        handleSelectChange(e, "nextActionDate");
                      }}
                      value={ConvertZWithoutFormat(userFormData.nextActionDate)}
                      isError={false}
                      helperText={""}
                      name={"nextActionDate"}
                    />
                  </MemoizedComponent>
                  {generateErrorList(
                    error.isValid,
                    error.errors,
                    "firstResponseDueDateTime"
                  )}
                </Grid>
                <Grid item md={1}>
                  <MemoizedComponent
                    userFormData={userFormData}
                    type="nextActionDesc"
                  >
                    <CustomFormLabel
                      labelName={"First action Description"}
                      isMandotary={false}
                    />
                    <CustomInput
                      isDisabled={false}
                      readOnly={false}
                      onClick={null}
                      iconPosition={"start"}
                      icon={false}
                      isError={false}
                      label={"Enter the First Action Description"}
                      name="nextActionDesc"
                      defaultValue={userFormData.nextActionDesc}
                      // value={userFormData.nextActionDesc}
                      helperText={""}
                      placeholder={
                        "Eg: Contact client to initiate investigation of the fault within SLA"
                      }
                    />
                  </MemoizedComponent>
                  {generateErrorList(
                    error.isValid,
                    error.errors,
                    "nextActionDescription"
                  )}
                </Grid>
              </Grid>
            </Box>
            <input
              id="AssetFileUpload1"
              accept=".jpeg, .img, .png, .pdf, .bmp, .doc, .msg, .docx, .jpg, .csv, .xlxs, .xls, .eml"
              type="file"
              multiple={false}
              onChange={(e: any) => {
                // setProgress(true);
                getBase64(e.target.files[0], imgCallBack);
              }}
              //value={FileUploadVal}
              className="d-none"
            />

            <label
              htmlFor="AssetFileUpload1"
              className="Add-Attachment"
              style={{
                fontSize: "16px",
                cursor: "pointer",
                float: "left",
                color: "#0A93FC",
                marginTop: "30px",
                marginBottom: "10px",
              }}
              onClick={(e: any) => {}}
            >
              + Add attachments
            </label>
            <div
              onClick={() => {
                navigate(`/history/${id}`);
              }}
              className="ticket-Timeline , modalCloseBtn"
              style={{
                display: "flex",
                color: "#0A93FC",
                marginLeft: "auto",
                marginTop: "40px",
                width: "max-content",
                alignItems: "center",
              }}
            >
              {id && (
                <>
                  Timeline <ArrowRightAltIcon />
                </>
              )}
            </div>

            <div className={style.buttonWrapper}>
              <BasicButton
                onClick={onCancelBtnClick}
                type={"button"}
                isLoading={false}
                isDisabled={false}
                color="primary"
                size="large"
                label={"Cancel"}
                variant={"contained"}
                endIcon={<CloseIcon sx={{ width: "20px" }} />}
              />

              <BasicButton
                type={"submit"}
                isLoading={loading}
                isDisabled={false}
                color="primary"
                label={"Next"}
                size="large"
                variant={"contained"}
                endIcon={<ArrowRightAltIcon />}
              />
            </div>
          </form>
        </Box>

        <RightSectionLayout
          style={style}
          primaryContact={userFormData.primaryContact}
          secondaryContact={userFormData.secondaryContact}
          contacts={ticketManagementProperties.contacts}
          permissions={permissions}
          assetSelected={userFormData.asset}
          onRemoveAsset={onRemoveAsset}
          attachment={userFormData.attachment}
          onRemoveAttchment={onRemoveAttchment}
          userFormData={userFormData}
          setFilterEmailData={setFilterEmailData}
          filterEmailData={filterEmailData}
        />
      </Box>
    </Box>
  );
};
export default Form;
