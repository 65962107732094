import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import GetAppRoundedIcon from "@mui/icons-material/GetAppRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import NavigateBeforeRoundedIcon from "@mui/icons-material/NavigateBeforeRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import SyncAltRoundedIcon from "@mui/icons-material/SyncAltRounded";
import { makeStyles } from "@mui/styles";
import MaterialTable from "material-table";
import { forwardRef, useCallback, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import BasicButton from "../../components/common/button/BasicButton";
import CustomDialog from "../../components/common/dialog/CustomDialog";
import { GetAssetLazy } from "../../redux/actions/AssetsManagement/Assets";
import { debounce } from "lodash";

export const tableIcons: any = {
  Search: forwardRef((props, ref) => <SearchRoundedIcon />),
  DetailPanel: forwardRef((props, ref) => <ChevronRightIcon />),
  Clear: forwardRef((props, ref) => <CloseRoundedIcon />),
  ResetSearch: forwardRef((props, ref) => <CloseRoundedIcon />),
  SortArrow: forwardRef((props, ref) => (
    <SyncAltRoundedIcon
      sx={{ color: "gray", transform: "rotate(90deg)", width: "15px" }}
    />
  )),
  Export: forwardRef((props, ref) => <GetAppRoundedIcon />),
  Filter: forwardRef((props, ref) => (
    <FilterListRoundedIcon sx={{ width: "20px" }} />
  )),
  FirstPage: forwardRef((props, ref) => (
    <FirstPageRoundedIcon sx={{ width: "20px" }} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPageRoundedIcon sx={{ width: "20px" }} />
  )),
  NextPage: forwardRef((props, ref) => (
    <NavigateNextRoundedIcon sx={{ width: "20px" }} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <NavigateBeforeRoundedIcon sx={{ width: "20px" }} />
  )),
};

const useStyles = makeStyles(() => ({
  wrapper: {
    "& .MuiInputBase-root": {
      fontSize: "14px",
      width: "150px",
      height: "30px",
    },
  },
}));

const AddAsset = ({
  setIsOpenAsset,
  isOpenAsset,
  selected,
  defaultContactFilter,
  onAssetSelected,
}: {
  setIsOpenAsset: any;
  isOpenAsset: boolean;
  selected: any;
  defaultContactFilter?: any;
  onAssetSelected: any;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedAssets, setSelectedAssets] = useState<any[]>([]);
  const [assetListToShow, setAssetListToShow] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const fetchAssets = (pageNum: number = 0) => {
    dispatch(
      GetAssetLazy(pageNum, true, defaultContactFilter, getAssetCB, pageSize)
    );
  };

  const fetchAssetsWithFilters = (
    pageNum: number = 0,
    assetName: string | null,
    contactName: string | null,
    type: string | null,
    department: string | null,
    tag: string | null
  ) => {
    dispatch(
      GetAssetLazy(
        pageNum,
        true,
        null,
        getAssetCB,
        pageSize,
        assetName,
        contactName,
        type,
        department,
        tag
      )
    );
  };

  const onChangeRowsPerPage = (pagesize: number) => {
    setPageSize(pagesize);
  };
  useEffect(() => {
    fetchAssets(0);
  }, [defaultContactFilter]);

  const getAssetCB = (
    pageNum: number,
    res: any,
    hasMoreAssets: number,
    totalCount: number
  ) => {
    if (pageNum > 0) {
      setAssetListToShow([...assetListToShow, ...res?.assets]);
    } else {
      setAssetListToShow([...res?.assets]);
    }
  };
  const handleSelectionChange = (selected: any) => {
    console.log("selected assets", selected);
    setSelectedAssets(selected);
  };

  const [filterVal, setFilterVal] = useState<Record<string, string | null>>({
    name: null,
    "contacts.name": defaultContactFilter,
    "assetType.name": null,
    "contacts.department": null,
    assetTag: null,
  });

  const handleFilterChange = useCallback(
    debounce((filters: any) => {
      const localFilters: Record<string, string | null> = {
        name: null,
        "contacts.name": null,
        "assetType.name": null,
        "contacts.department": null,
        assetTag: null,
      };

      // Update the newFilters object based on the filters received
      filters.forEach((filter: any) => {
        localFilters[filter.column.field] = filter.value;
      });

      // Update the state
      setFilterVal(localFilters);

      fetchAssetsWithFilters(
        0,
        localFilters["name"],
        localFilters["contacts.name"],
        localFilters["assetType.name"],
        localFilters["contacts.department"],
        localFilters["assetTag"]
      );
    }, 1000),
    []
  );

  return (
    <>
      <CustomDialog
        heading="Add Asset"
        body={
          <>
            <div className={`addAssetModal ${classes.wrapper}`}>
              <MaterialTable
                totalCount={totalCount}
                onChangePage={fetchAssets}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onSelectionChange={handleSelectionChange}
                options={{
                  filtering: true,
                  sorting: false,
                  exportButton: true,
                  showTitle: false,
                  selection: true,
                  showFirstLastPageButtons: false,
                  paginationType: "stepped",
                  headerStyle: {
                    backgroundColor: "#EAF2FD",
                  },
                  maxBodyHeight: "300px",
                }}
                localization={{
                  toolbar: {
                    nRowsSelected: "{0} Asset(s) selected",
                  },
                  body: {
                    emptyDataSourceMessage: "No Assets to display",
                  },
                }}
                style={{ border: "1px solid white", boxShadow: "none" }}
                columns={[
                  {
                    title: "Photo of asset",
                    field: "asset.name",
                    render: (rowData: any) => (
                      <div
                        style={{
                          height: 36,
                          width: 10,
                          borderRadius: "5px",
                          boxShadow:
                            "7px 10px 17px -4px rgba(115,109,109,0.48)",
                        }}
                      >
                        {rowData.src}
                      </div>
                    ),
                  },
                  {
                    title: "Name",
                    field: "name",
                    defaultFilter: filterVal["name"],
                  },
                  {
                    title: "Contact",
                    field: "contacts.name",
                    defaultFilter: filterVal["contacts.name"],
                  },
                  {
                    title: "Type",
                    field: "assetType.name",
                    defaultFilter: filterVal["assetType.name"],
                  },
                  {
                    title: "Department",
                    field: "contacts.department",
                    defaultFilter: filterVal["contacts.department"],
                  },
                  {
                    title: "Tags",
                    field: "assetTag",
                    defaultFilter: filterVal["assetTag"],
                  },
                ]}
                data={assetListToShow}
                title=""
                icons={tableIcons}
                isLoading={loading}
                onFilterChange={handleFilterChange}
              />
            </div>
          </>
        }
        open={isOpenAsset}
        handleClose={() => {
          setIsOpenAsset(false);
        }}
        footer={
          <>
            <div style={{ display: "flex", gap: 10 }}>
              <BasicButton
                onClick={() => {
                  setIsOpenAsset(false);
                }}
                type={"cancel"}
                isLoading={false}
                isDisabled={false}
                color="primary"
                size="large"
                label={"Cancel"}
                variant={"outlined"}
                endIcon={<CloseIcon sx={{ width: "20px" }} />}
              />
              <BasicButton
                onClick={() => {
                  const total: any = [...selected, ...selectedAssets];
                  const ids = total.map((o: any) => o.id);
                  const filtered = total.filter(
                    ({ id }: { id: any }, index: any) =>
                      !ids.includes(id, index + 1)
                  );

                  if (filtered.length > 0) {
                    console.log("filtered onAssetSelected", filtered);
                    onAssetSelected(filtered);
                  }
                  setIsOpenAsset(false);
                }}
                type="submit"
                label={`Select Asset(s)`}
                isLoading={false}
                isDisabled={false}
                color="primary"
                size="large"
                variant={"contained"}
                endIcon={<AddRoundedIcon sx={{ width: "20px" }} />}
              />
            </div>
          </>
        }
      />
    </>
  );
};
export default AddAsset;
