import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomFormLabel from "../../common/form-label/CustomFormLabel ";
import CustomInput from "../../common/textField/CustomInput";
import CustomDialog from "../../common/dialog/CustomDialog";
import BasicButton from "../../common/button/BasicButton";
import CustomTree from "../../common/custom-tree/CustomTree";
import { getCategoryByTypeId } from "../../../redux/actions/SystemManagement/AddCategory";
import { useDispatch } from "react-redux";
import { getCategoryByID } from "../../../redux/actions/TicketsManagement/TicketsManagement";

function CloseCategoryOption({ ticketTypeId, onSelectOutcome,outComeCategoryId }: { ticketTypeId: any; onSelectOutcome: (id: any) => void,outComeCategoryId:any }) {
  const dispatch = useDispatch();
  const [addCategoryPopup, setAddCategoryPopup] = useState(false);
  const [statusCode, setStatusCode] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [categoryVal, setcategoryVal] = React.useState<any>({});
  const [value, setValue] = React.useState<any>({});
  // console.log("ticketInfo?.ticketLogs?.agentId", outComeCategoryId);
  useEffect(() => {
    if (outComeCategoryId) 
      dispatch(getCategoryByID(outComeCategoryId,getOutComeCB));
  }, [outComeCategoryId]);
  const getOutComeCB = (res:any) =>{
    console.log("getOutComeCB",res)
    setValue({ ...res, label: res.name, value: res.name });
}
  useEffect(() => {
    setValue(null);
    if (ticketTypeId) {
      setLoading(true)
      dispatch(getCategoryByTypeId(ticketTypeId, singleCategoryCB, true));
    }
  }, [ticketTypeId]);

  const singleCategoryCB = (res: {
    isSuccess: boolean;
    data: any;
    statusCode: any;
  }) => {
    const { isSuccess, data, statusCode } = res;
    setStatusCode(statusCode);
    setLoading(false)
    if (isSuccess) {
      setcategoryVal(data);
    }
  };
  const handleCategorySelection = (val: any) => {
    setValue(val);
    onSelectOutcome(val?.id)
  }
  if (loading) {
    return <div>Loading...</div>
  }
  return (
    <>
      {statusCode === 204 ? (
        <></>
      ) : (
        <>
          <Box>
            <CustomFormLabel labelName={"Outcomes"} isMandotary={true} />
            <CustomInput
              isDisabled={false}
              readOnly={true}
              iconPosition={"start"}
              icon={false}
              isError={false}
              label={"Outcomes"}
              name="closeCategory"
              value={value?.label}
              onClick={() => {
                setAddCategoryPopup(true);
              }}
              onChange={() => {}}
              helperText={""}
              placeholder={"Click here to select an Category"}
            />
          </Box>
          <CustomDialog
            heading="Outcome"
            className="outcome-popup"
            body={
              <>
                <div className="category-content" style={{ width: "400px" }}>
                  {categoryVal && (
                    <CustomTree
                      title={categoryVal?.name || ""}
                      data={categoryVal?.subCategoryList || []}
                      defaultValue={value}
                      onSelect={handleCategorySelection}
                    />
                  )}
                </div>
              </>
            }
            open={addCategoryPopup}
            handleClose={() => {
              setAddCategoryPopup(false);
            }}
            footer={
              <>
                <BasicButton
                  type={"submit"}
                  isLoading={false}
                  isDisabled={false}
                  color="primary"
                  label={"Save"}
                  onClick={() => {
                    setAddCategoryPopup(false);
                  }}
                  size="large"
                  variant={"contained"}
                  endIcon={false}
                />
              </>
            }
          />
        </>
      )}
    </>
  );
}

export default CloseCategoryOption;
