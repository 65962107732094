import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { debounce } from "lodash";
import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { triggerAppAlert } from "../../../../redux/actions/SelfServicePortal/ContactAid/ContactAid";
import {
  bulkUpdateNewTickets,
  getBulkNewTicket,
  storeAllCSVResponses,
} from "../../../../redux/actions/TicketsManagement/TicketsManagement";
import { IState } from "../../../../redux/reducers/rootReducers";
import CustomDialog from "../../../common/dialog/CustomDialog";
import Breadcrumbs from "../../../common2/Breadcrumbs";
import ConfirmationPopup from "../../../common2/ConfirmationPopup";
import TopFilterNew from "../../../common2/TopFilterNew";
import CSVLoader from "../../../ReportsTest/CSVLoader";
import AgentGroupOption from "./AgentGroupOption";
const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "sourceCode",
    headerName: "Source",
    width: 150,
  },
  {
    field: "ticketTitle",
    headerName: "Title",
    width: 250,
  },
  {
    field: "createdDateTime",
    headerName: "Opened Date",
    width: 150,
  },
  {
    field: "group",
    headerName: "Group",
    width: 150,
  },
  {
    field: "contact",
    headerName: "Contact",
    width: 150,
  },
  {
    field: "contactOrganisation",
    headerName: "Organisation",
    width: 150,
  },
];
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      {/* <GridToolbarColumnsButton /> */}
      <GridToolbarFilterButton />
      {/* <GridToolbarDensitySelector />
        <GridToolbarExport /> */}
    </GridToolbarContainer>
  );
}
interface ITicketsProps {
  allTickets: any;
  allResponses: any;
  dispatch: any;
}
export function BulkAssignTicket({
  allTickets = [],
  allResponses = {},
  dispatch,
}: ITicketsProps) {
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  function loadTickets() {
    if (allTickets.length > 0) {
      const mappedTickets = allTickets.map((ticket: any) => ({
        id: ticket.id,
        sourceCode: ticket.sourceCode,
        ticketTitle: ticket.ticketLogs?.title || "N/A", // Handle missing ticketLogs
        createdDateTime: ticket.createdDateTime,
        group: ticket.ticketLogs?.group?.name,
        contact: ticket.ticketLogs?.contacts?.name,
        contactOrganisation:
          ticket.ticketLogs?.contacts?.contactOrganisation?.name,
      }));
      return mappedTickets; // Return the transformed data
    } else {
      // Do API call
      const loadTickets = debounce(
        (
          search: string = "",
          statusIds: number[] = [],
          groupIds: number[] = []
        ) => {
          dispatch(getBulkNewTicket(search, statusIds, groupIds, 100));
        },
        150
      );
    }
  }

  const table = React.useMemo(() => {
    return (
      <DataGrid
        rows={loadTickets()}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{
          toolbar: CustomToolbar,
        }}
        onRowSelectionModelChange={(ids) => setRowSelectionModel(ids)}
      />
    );
  }, [rowSelectionModel.length]);

  const getAllTicket = (searchVal?: string) => {
    console.log(
      "AllNewTickets",
      allResponses?.ticketStatus,
      allResponses?.groups
    );
    const statusIds: number[] = [];
    const groupIds: number[] = [];
    allResponses?.ticketStatus?.map((i: any) => {
      if (i.name !== "New" && i.name !== "Delete") {
        statusIds.push(i.id);
      }
    });

    allResponses?.groups?.map((i: any) => {
      groupIds.push(i.id);
    });
    loadTickets();
  };

  return (
    <>
      <Breadcrumbs
        data={[
          {
            title: "Ticket Management ",
            path: "/TicketManagementSys",
          },
          {
            title: "Bulk Assign Ticket ",
            path: "/BulkAssignTicket",
          },
        ]}
      />
      <Box
        className="allNew-tickets"
        style={{ marginTop: "10px", height: "80vh", width: "100%" }}
      >
        {"Loading..."}

        {/* {allTickets?.length} {rowSelectionModel?.length} */}
        <FilterTickets
          rowSelectionModel={rowSelectionModel}
          loadTickets={getAllTicket}
        />

        {table}
      </Box>
    </>
  );
}

const FilterTickets = ({ loadTickets, rowSelectionModel }: any) => {
  const dispatch = useDispatch();
  const allResponses = useSelector(
    (state: IState) => state?.TicketsReducer?.allResponses?.ticketStatus
  );
  const [showAssign, setShowAssign] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState({
    search: "",
    isActive: true,
  });
  const filterCallback = React.useCallback((values: any) => {
    setFilterValues(values);
  }, []);
  React.useEffect(() => {
    // LoadTickets
    loadTickets(filterValues.search);
  }, [filterValues]);

  const deleteTickets = () => {
    setShowDelete(true);
  };

  const assignTickets = () => {
    setShowAssign(true);
  };

  const proceedDeleteItems = () => {
    const deleteId = allResponses.find((x: any) => x.name === "Delete")?.id;
    console.log("rowSelectionModel", rowSelectionModel, allResponses, deleteId);

    if (!deleteId) {
      dispatch(
        triggerAppAlert(
          `User doesn't have Role Permission to Delete! Please contact Admin.`,
          "warning"
        )
      );
      return;
    }
    const payload = {
      sourceCode: "System",
      ticketLogs: {
        sourceCode: "System",
        groupId: 0,
        agentId: 0,
        statusId: deleteId,
      },
    };
    dispatch(
      bulkUpdateNewTickets(rowSelectionModel, payload, bulkUpdateNewTicketsCB)
    );
  };

  const bulkUpdateNewTicketsCB = () => {
    setShowDelete(false);
    loadTickets(filterValues.search);
  };

  return (
    <>
      <TopFilterNew
        callback={filterCallback}
        backNavigationPath="/TicketManagementSys"
        hideAddBtn={true}
        hideToggleBtns={true}
        extraFeatures={
          <>
            {rowSelectionModel.length > 0 && (
              <>
                <Tooltip title={`Delete Selected Items`}>
                  <Button onClick={deleteTickets}>
                    <DeleteOutlineIcon />
                  </Button>
                </Tooltip>
                <Tooltip title={`Assign Selected Items`}>
                  <Button onClick={assignTickets}>
                    <GroupAddIcon />{" "}
                  </Button>
                </Tooltip>
                <Typography component="span">
                  {rowSelectionModel.length} Item
                  {rowSelectionModel.length === 1 ? "" : "s"} selected.
                </Typography>
              </>
            )}
          </>
        }
      />

      {showDelete && (
        <ConfirmationPopup
          onClose={() => {
            setShowDelete(false);
          }}
          onSubmit={proceedDeleteItems}
          title="Warning"
          desc="Are you sure you want to delete the selected tickets?"
        />
      )}
      {showAssign && (
        <>
          <CustomDialog
            heading={`Assign Agent!`}
            body={
              <>
                <AssignAgentForm
                  selectedTicketIds={rowSelectionModel}
                  handleClose={() => {
                    setShowAssign(false);
                  }}
                />
              </>
            }
            open={true}
            handleClose={() => {
              setShowAssign(false);
            }}
          />
        </>
      )}
    </>
  );
};

const AssignAgentForm = ({ selectedTicketIds, handleClose }: any) => {
  return (
    <>
      <AgentGroupOption
        selectedTicketIds={selectedTicketIds}
        handleClose={handleClose}
      />
    </>
  );
};

const mapStateToProps = (state: IState) => {
  const allTickets = state?.AllTicketsReducer?.newToMeTickets || [];
  const otherTickets = state?.AllTicketsReducer?.otherTickets || [];
  return {
    allTickets: [...allTickets, ...otherTickets], // Merge both arrays
    allResponses: state?.TicketsReducer?.allResponses,
  };
};
export default connect(mapStateToProps)(BulkAssignTicket);
