import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { IState } from "../../redux/reducers/rootReducers";
import TicketGroups from "../Dashboard/TicketManagement/GroupsList";
import NewTicketsBox from "../Dashboard/TicketManagement/TicketDashboardWidgets/NewTickets";
import NewtoMeTicketsBox from "../Dashboard/TicketManagement/TicketDashboardWidgets/NewtoMeTickets";
import ViewHolder from "../Dashboard/TicketManagement/TicketDashboardWidgets/views/viewHolder";
import CommonAppFilter from "../navbar/sideNavbar/CommonAppFilter";
import TicketAddUpdatePopup from "./Modules/AddUpdatePopup";
import { ContactPopup } from "./Modules/ContactPopup";
import { TicketViewPopup } from "./Modules/TicketViewPopup";
import WhatsAppModal from "./Modules/WhatsAppModal";
import SendEmailPopup from "../../pages/new-ticket/SendEmailPopup";
import ResolvePopup from "./Modules/ResolvePopup";
import MergePopup from "../Dashboard/TicketManagement/TicketDashboardWidgets/views/widgets/MergePopup";
import OnHoldMessagePopup from "./Modules/OnHoldMessagePopup";
import DataPreloadedWrapper from "../../util/DataPreloadedWrapper";

const generateSendEmailPopup = (
  userFormData: any,
  submittedData: any,
  isSendEmailPopup: any,
  setIsSendEMailPopup?: any,
  filterEmailData?: any
) => {
  if (isSendEmailPopup) {
    return (
      <SendEmailPopup
        setEditData={false}
        filterEmailData={filterEmailData}
        type="add"
        isSendEmailPopup={isSendEmailPopup}
        setIsSendEMailPopup={setIsSendEMailPopup}
        submittedData={{
          ...submittedData,
          contactList: userFormData.contactList,
        }}
        setData={false}
      />
    );
  }
};
const TicketManagementSys = () => {
  useEffect(() => {
    // Add class to body when the component mounts
    document.body.classList.add("hide-scrollbar");
    return () => {
      document.body.classList.remove("hide-scrollbar");
    };
  }, []);

  return (
    <>
      <DataPreloadedWrapper>
        <>
          <TicketManagementSysBody />
        </>
      </DataPreloadedWrapper>
    </>
  );
};
const TicketManagementSysBody = memo(() => {
  const classes = useStyles();
  const [expanded, setPageExpanded] = useState(false);
  const AllTickets = useSelector((state: IState) => state?.AllTicketsReducer);
  const sendEmailPreview = useSelector(
    (state: IState) => state?.TicketViewReducer?.sendEmailView
  );
  const [isSendEmailPopup, setIsSendEMailPopup] = useState<boolean>(false);

  useEffect(() => {
    console.info("sendEmailPreview", sendEmailPreview);
    if (sendEmailPreview) {
      setIsSendEMailPopup(true);
    } else {
      setIsSendEMailPopup(false);
    }
  }, [sendEmailPreview]);
  // const AllTickets = useSelector((state: IState) => state?.AllTicketsReducer);
  const loader: any = useSelector(
    (state: IState) => state?.LayoutTicketManagementReducer?.showLoader
  );
  const filter = useMemo(() => <CommonAppFilter />, [classes]);
  const firstRow = useMemo(
    () => (
      console.log("expanded.................", expanded),
      (
        <Box
          style={{
            opacity: expanded ? 0 : 1,
            maxHeight: expanded ? 0 : "200px",
            overflow: "hidden",
            transition: "opacity 0.8s ease-in-out",
            // transition: "opacity 1s ease, max-height 1s ease",
          }}
          className={`someTest- ${classes.row}`}
        >
          <NewTicketsBox />
          <NewtoMeTicketsBox />
          <TicketGroups />
        </Box>
      )
    ),
    [AllTickets, expanded, classes]
  );
  const LoaderPart = useMemo(
    () => (
      <>
        {loader && (
          <Box className={classes.loaderBox}>
            <CircularProgress />
          </Box>
        )}
      </>
    ),
    [loader, classes]
  );

  const [firstLoad, setFirstLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => setFirstLoad(false), 10);
  }, []);

  const views = useMemo(
    () => (
      <>
        {!firstLoad && (
          <ViewHolder expanded={expanded} setPageExpanded={setPageExpanded} />
        )}
      </>
    ),
    [firstLoad, expanded, classes]
  );
  const contactPopup = useMemo(() => <ContactPopup />, [classes]);
  const ticketView = useMemo(() => <TicketViewPopup />, [classes]);
  const addUpdate = useMemo(() => <TicketAddUpdatePopup />, [classes]);
  const whatsAppModal = useMemo(() => <WhatsAppModal />, [classes]);
  // const resolvePopup = useMemo(() => <ResolvePopup />, [classes]);
  // const mergePopup = useMemo(() => <MergePopup />, [classes]);
  // const [expanded, setExpanded] = useState(false);
  return (
    <>
      {filter}
      <hr className="Ticket-management-hr" />
      {LoaderPart}
      {/* <p>pop{AllTickets?.otherTickets?.length}</p> */}
      {firstRow}
      <Box className="pt-1">{views}</Box>
      {contactPopup}
      {ticketView}
      {addUpdate}
      {whatsAppModal}
      {/* {resolvePopup} */}
      <ResolvePopup />
      <MergePopup />
      <OnHoldMessagePopup />
      {generateSendEmailPopup(
        [],
        sendEmailPreview,
        isSendEmailPopup,
        setIsSendEMailPopup,
        []
      )}
    </>
  );
});

const useStyles: any = makeStyles(() => ({
  row: {
    display: "flex",
    gap: "4px",
  },
  rowWrap: {
    flexWrap: "wrap",
  },
  loaderBox: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "10px",
    padding: "15px",
    "& span": {
      width: "20px !important",
      height: "20px  !important",
    },
  },
}));
export default TicketManagementSys;
