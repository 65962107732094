import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../../../redux/reducers/rootReducers";
import { Box } from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomTheme from "../../../../common/CustomTheme";
import PriorityTitle from "./widgets/PriorityTitle";
import PriorityColumn from "./widgets/PriorityColumn";

const data = CustomTheme();
const mode = data.theme;

export const TicketPriorityView = memo(() => {
  const classes = useStyles();
  const AllPriorities: any = useSelector(
    (state: IState) => state?.TicketsReducer?.allResponses?.priorities
  );
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const priorityList = useMemo(() => {
    if (!isLoaded) {
      return <>LOADING...</>;
    }

    if (!AllPriorities?.length) {
      return <>No Priorities Found</>;
    }

    const subtractionValues: Record<number, number> = {
      7: 17,
      6: 19,
      5: 23,
      4: 28,
      3: 37,
      2: 56,
      1: 110,
    };
    
    const getDynamicWidth = (ticketStatusCount:number) => {
      console.log("TKT priority COUNT:::",ticketStatusCount)
    
      return window.innerWidth >= 1550
        ? `calc(calc(100vw / ${ticketStatusCount >= 7 ? 7 : ticketStatusCount}) - ${subtractionValues[ticketStatusCount >= 7 ? 7 : ticketStatusCount]}px)`
        : `calc(calc(100vw / ${ticketStatusCount >= 6 ? 6 : ticketStatusCount}) - ${subtractionValues[ticketStatusCount >= 6 ? 6 : ticketStatusCount]}px)`;
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "visible",
          overflowY: "visible",
          whiteSpace: "nowrap",
          maxWidth: "100%",
          maxHeight: "50%",
        }}
        className="p-5p"
      >
        {/** The child container that holds all columns */}
        <Box
          sx={{ display: "flex", flexWrap: "nowrap" }}
          className={`${classes.row} pt-1 px-2`}
        >
          {AllPriorities.map((priority: any, index: number) => (
            <PriorityItem
              key={index}
              name={priority?.name}
              color={priority?.color}
              id={priority?.id}
              width={getDynamicWidth(AllPriorities?.length)}
            />
          ))}
        </Box>
      </Box>
    );
  }, [AllPriorities, isLoaded, classes]);

  return <>{priorityList}</>;
});



const PriorityItem = memo(
  ({
    name,
    color,
    id,
    key,
    width
  }: {
    name: string;
    color: string;
    id: number;
    key: number;
    width: string;
  }) => {
    const shrinkVal: any = useSelector(
      (state: IState) => state?.LayoutTicketManagementReducer?.columnShrinked
    );

    const content = useMemo(() => {
      return (
        <>
          <PriorityTitle title={name} color={color} />
          <PriorityColumn columnId={id} />
        </>
      );
    }, [name, color, id]);

    return (
      <Box
        style={{
          width: width,
        }}
        className={`${
          shrinkVal ? "col min-150 shrinked-cardCol" : "col-4"
        } trans-5s pr-10 position-relative`}
        // sx={{
        //   minWidth: 250,
        // }}
      >
        {content}
      </Box>
    );
  }
);

const useStyles = makeStyles(() => ({
  whiteBox: {
    padding: "20px",
    background: mode.whiteSolid,
    "&.emailTemplateMailBox": {
      "& .cmn-inputBox": {
        margin: 0,
        "& .sm-pd": {
          padding: 0,
          "& > div": {
            background: "none",
            "& fieldset": {
              border: "none",
              borderBottom: "1px solid #f1f1f1",
            },
          },
        },
      },
    },
  },
  row: {
    display: "flex",
  },
}));

export default TicketPriorityView;
