import { Box, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Sortable from "sortablejs";
import { loadOtherTickets } from "../../../../../redux/actions/TicketsManagement/TicketsManagement";
import { IState } from "../../../../../redux/reducers/rootReducers";
import TableView from "./TableExp";
import TicketPriorityView from "./TicketPriorityView";
import TicketStatusView from "./TicketStatusView";
import CommonStyles from "../../../../common/CommonStyles";

// const TicketPriorityView = lazy(()=>import("./TicketPriorityView"));
// const TicketStatusView = lazy(()=>import("./TicketStatusView"));
// const TableExp = lazy(()=>import("../../TableExp"));

interface ViewHolderProps {
  expanded: boolean;
  setPageExpanded: (value: boolean) => void;
}

const TableViewWrapper = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let allGroups: any = document.getElementById(`ListView`);
    if (!allGroups) return;
    var sortable = Sortable.create(allGroups, {
      animation: 150,
      group: "list",
      onAdd: function (e: any) {
        let isMyTickets = e.item.getAttribute("data-mytickets");
        let ticketId = e.item.getAttribute("data-ticket-id");
        console.log("isMyTickets", isMyTickets);
        if (isMyTickets === "myTickets" || isMyTickets === "newTickets") {
          //alert('redirect')
          navigate(`/home/ticket/${ticketId}`);
          return;
        }
        e.clone.replaceWith(e.item);
      },
    });
  }, []);
  return (
    <>
      <div className="pos-rel">
        <div id={`ListView`} className="column-overlay"></div>
        <TableView />
      </div>
    </>
  );
};

// ViewHolder.tsx
export const ViewHolder: React.FC<ViewHolderProps> = memo(
  ({ expanded, setPageExpanded }) => {
    const LayoutType: any = useSelector(
      (state: IState) => state?.LayoutTicketManagementReducer?.layout
    );
    const [isLoaded, setIsLoaded] = useState(false);
    const [viewVal, setViewVal] = useState(
      LayoutType ? LayoutType : "Priority"
    );
    const classes = CommonStyles();
    const childRef = useRef(null);

    const handleScroll = () => {
      // If the user has scrolled down even 1px, set expanded to true
      if (
        childRef.current &&
        (childRef.current as HTMLElement).scrollTop > 200
      ) {
        console.log("scrolled");
        setPageExpanded(true);
      } else if (childRef.current &&
        (childRef.current as HTMLElement).scrollTop === 0){
          setPageExpanded(false);
        }
    };
    useEffect(() => {
      setIsLoaded(true);
    }, []);

    useEffect(() => {
      setViewVal(LayoutType);
      console.log("when clicked", LayoutType);
    }, [LayoutType]);

    return (
      <Box
        className={`${classes.whiteBox} padd-rlb-5p`}
        ref={childRef}
        onScroll={handleScroll}
        sx={{
          maxHeight: expanded ? "calc(100vh - 160px)" : "calc(100vh - 345px)",
          overflowX: "visible",
          overflowY: "auto",
          whiteSpace: "nowrap",
          display: "flex",
          flexDirection: "column",
          transition: "height 0.8s ease-out-in",
        }}
      >
        {/* This nested Box forces horizontal overflow if your ticket columns are wide */}
        <Box sx={{ minWidth: "1200px" }}>
          <Typography
            className={`${classes.title} ${classes.themeColorTxt}`}
            color="textSecondary"
            textAlign="center"
          >
            <Link className="newTickets-link" to="/BulkAssignTicket">
              Bulk Assign Ticket
            </Link>
          </Typography>

          {/* Render your main views */}
          {viewVal === "Priority" && <TicketPriorityView />}
          {viewVal === "Status" && <TicketStatusView />}
          {viewVal === "List" && <TableViewWrapper />}
        </Box>

        {/* Now place these components INSIDE the same Box */}
        <NoTickets />
        <LoadMore />
      </Box>
    );
  }
);

const NoTickets = memo(() => {
  //const {AllTickets} = props;
  const AllTickets = useSelector(
    (state: IState) => state?.AllTicketsReducer?.otherTickets
  );
  return (
    <>
      {AllTickets?.length === 0 && (
        <Box className={"text-center No-Tickets"}>No Tickets Found</Box>
      )}
    </>
  );
});
const LoadMore = () => {
  const dispatch = useDispatch();
  const classes = loadMoreBtnStyles();
  const AllData = useSelector(
    (state: IState) => state?.TicketsReducer?.allResponses
  );
  const loading = useSelector(
    (state: IState) => state?.LayoutTicketManagementReducer?.showLoader
  );
  const FiltersVal: any = useSelector(
    (state: IState) => state?.filtersTicketManagementReducer
  );
  const hasMoreTickets = useSelector(
    (state: IState) => state?.AllTicketsReducer?.hasOtherTickets
  );
  const pageCount = useSelector(
    (state: IState) => state?.AllTicketsReducer?.pageNoOtherTickets
  );
  const AllTickets = useSelector(
    (state: IState) => state?.AllTicketsReducer?.otherTickets
  );
  const loadMoreData = useCallback(() => {
    if (AllTickets.length === 0) return;
    dispatch(
      loadOtherTickets(FiltersVal, AllData, pageCount + 1, loadOtherTicketsCB)
    );
  }, [AllTickets, FiltersVal, AllData, pageCount]);
  const loadOtherTicketsCB = (res: any) => {
    //setLoading(false);
  };
  useEffect(() => {
    console.log("AllTickets,hasMoreTicketsAllTickets,hasMoreTickets", loading);
  }, [loading]);
  const pageContent = useMemo(
    () => (
      <>
        {AllTickets?.length !== 0 && hasMoreTickets && (
          <Box className={classes?.loadMoreBtn}>
            {loading ? (
              <>Loading...</>
            ) : (
              <span onClick={loadMoreData}>Load More</span>
            )}
          </Box>
        )}
      </>
    ),
    [AllTickets, hasMoreTickets, classes, loading]
  );
  return <>{pageContent}</>;
};
export const loadMoreBtnStyles = makeStyles(() =>
  createStyles({
    loadMoreBtn: {
      flex: "0 0 0",
      padding: "10px 10px 0px 10px",
      fontSize: ".8em",
      textAlign: "center",
      color: "#1261b4",
      "& span": {
        cursor: "pointer",
        display: "inline-block",
        border: "1px solid",
        borderRadius: "3px",
        padding: "5px 15px",
      },
    },
  })
);
export default ViewHolder;
