import { IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/Clear";
const theme = createTheme();
const useStyles = makeStyles(() => ({
  wrapper: {
    "& p": {
      marginLeft: 0,
    },
    width: "100% !important",
  },
}));

export default function CustomInput({
  isError,
  label,
  helperText,
  placeholder,
  onBlur,
  value,
  onChange,
  icon,
  iconPosition,
  name,
  onClick,
  readOnly,
  isDisabled,
  defaultValue,
  type,
  classNames = "",
  onFocus,
  onClearClick,
}: {
  classNames?: string;
  isError?: any;
  label: string;
  helperText?: any;
  placeholder: string;
  value?: any;
  onChange?: any;
  onBlur?: any;
  icon?: any;
  iconPosition?: any;
  name?: any;
  onClick?: any;
  readOnly?: boolean | undefined;
  isDisabled?: any;
  type?: string;
  defaultValue?: any;
  onFocus?: any;
  onClearClick?: any;
}) {
  const classes = useStyles();

  return (
    <div
      className={`${classes.wrapper} custom-multiselect-input ${classNames}`}
    >
      <TextField
        className="Textarea"
        type={type}
        disabled={isDisabled}
        sx={{
          width: "100%",
          boxShadow: "none",
          "& .MuiInputBase-root": {
            backgroundColor: "white",
            height:
              name == "body" ||
              name == "desc" ||
              name == "nextActionDesc" ||
              name == "note"
                ? "auto"
                : 52,
            // minHeight: name=="note" ? 100 : 52,
            fontSize: 14,
            paddingLeft: "5px",
            border: "1px solid #ddd !important ",
            boxShadow: "none",
            "& fieldset": {
              border:
                name == "Emailsubject" || name == "body"
                  ? "none"
                  : "0.5px solid #cccccc",
            },
          },
          "& .Mui-error": {
            color: "red",
            opacity: 1,
            "& svg": {
              color: "red !important",
            },
          },
          "& .Mui-disabled": {
            WebkitTextFillColor: "black",
            backgroundColor: "#EAEAEA",
            cursor: "not-allowed",
          },
        }}
        autoComplete="false"
        multiline={
          name == "body" ||
          name == "desc" ||
          name == "nextActionDesc" ||
          name == "note"
            ? true
            : false
        }
        error={isError}
        id={name}
        name={name}
        InputLabelProps={{ shrink: false }}
        value={value}
        defaultValue={defaultValue}
        onClick={onClick}
        onChange={onChange}
        onBlur={onBlur}
        helperText={helperText}
        placeholder={placeholder}
        onFocus={onFocus}
        InputProps={{
          startAdornment: (
            <InputAdornment position={iconPosition}>{icon}</InputAdornment>
          ),
          endAdornment: onClearClick && (
            <InputAdornment position="end">
              {value && (
                <IconButton onClick={(e) => onClearClick(e)} size="small">
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
          readOnly: readOnly,
        }}
      />
    </div>
  );
}
