import { memo, useEffect } from "react";
import CommonStyles from "../../../../../common/CommonStyles";
import { Box, Typography } from "@mui/material";

export const PriorityTitle = memo((props: any) => {
  const { title, color } = props;
  // const classes = CommonStyles();
  return (
    <Typography
      variant="body2"
      className="text-center makeHeaderSticky"
      //   sx={{
      //     position: "sticky",
      //     top: 0,
      //     background: "white", // Ensure it's visible when sticky
      //     zIndex: 10, // Keeps it above other content
      //     padding: "8px 0", // Adds spacing
      //     backgroundColor: "pink",
      //   }}
    >
      {title} Priority Tickets
      {/* <Box
        //     className={`${classes.tcktPriorityTitle}`}
        //     sx={{ background: color ? color : "#ddd" }}
        // >
        //     {title} Priority Tickets
        // </Box> */}
    </Typography>
  );
});
export default PriorityTitle;
