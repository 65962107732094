import { ITicket } from "../../actions/TicketsManagement/TicketsManagement";
import {
  STORE_NEW_TICKETS,
  STORE_NEW_TO_ME_TICKETS,
  STORE_PRIORITY_TICKETS,
} from "../../actions/TicketsManagement/TicketsManagementNew";

const initialState = {
  allTickets: {},
  allNewTickets: {},
  allResponses: {},
  alldataStructure: {},
  dashboardSummary:{},
  queryParam: "",
  filterVal: {},
  newTickets: [],
  newToMeTickets: [],
  priorityTickets: [],
  //home dashboard
  dashboardGroup:{},
  homeDashboardFilter:{
    groupIds:{},
    group:{},//group&agent
    priority:{},
    ticketType:{},
  },
};
const initialStateForReport = {
  allTickets: [],
  allTicketLogs:[]
};
const initialState1 = {
  loader: false,
  allTickets: [],
  newTickets: [],
  newToMeTickets: [],
  otherTickets: [],
  hasNewTickets: true,
  hasNewToMeTickets: true,
  hasOtherTickets: true,
  pageNoNewTickets: 0,
  pageNoNewToMeTickets: 0,
  pageNoOtherTickets: 0,
  calendarTickets:[],
  mobileActionsPopover:null,
  mobileSort:false,
  mobileFilter:false,
  sendEmail: null,
  mobileAddUpdate:null,
  contactInfo:null,
  dashboardSummary:{}
};
const toggleObjectKeyForFilter = (oldVal:{[key : string]:any},newObj:{[key : string]:any}) =>{
  const key:string = Object.keys(newObj)[0] || "";
  if(oldVal[key]){
    delete oldVal[key];
    return oldVal
  }else{
    return { ...oldVal, ...newObj }
  }
}
const TicketsReducer = (
  state = initialState,
  action: { type: any; payload: any }
) => {
  console.log(state);
  console.log("state");
  console.log(action.payload);
  console.log("action.payload");
  
  switch (action.type) {
    case "STORE_ALL_TICKETS":
      return {
        ...state,
        allTickets: action.payload,
      };
    case "STORE_ALL_NEW_TICKETS":
      return {
        ...state,
        allNewTickets: action.payload,
      };
    case "STORE_FILTER":
      return {
        ...state,
        filterVal: action.payload,
      };
    case "STORE_ALL_STRUCTURES":
      return {
        ...state,
        alldataStructure: action.payload,
      };
    case "UPDATE_ALL_STRUCTURES":
        return {
          ...state,
          alldataStructure: {
            ...state.alldataStructure,
            ...action.payload
          },
        };
    case "UPDATE_ALL_RESPONSES":
          return {
            ...state,
            allResponses: {
              ...state.allResponses,
              ...action.payload
            },
          };
    case "STORE_DASHBOARD_GROUPS":
          return {
            ...state,
            dashboardGroup: action.payload,
            homeDashboardFilter: {
              group:{},
              groupIds: action.payload,
              priority:{},
              ticketType:{},
            }
          }; 
    case "CLEAR_HOME_DASHBOARD_FILTER":
        return {
          ...state,
          dashboardGroup: {},
          homeDashboardFilter: {
            group:{},
            groupIds: {},
            priority:{},
            ticketType:{},
          }
        }; 
    case "STORE_HOME_DASHBOARD_TCKT_TYPE":
          return {
            ...state,
            homeDashboardFilter: {
              ...state.homeDashboardFilter,
              ticketType : toggleObjectKeyForFilter(state.homeDashboardFilter.ticketType,action.payload)
            }
          }; 
    case "STORE_HOME_DASHBOARD_AGENT_GROUP":
      return {
        ...state,
        homeDashboardFilter: {
          ...state.homeDashboardFilter,
          // groupIds : {},
          group : toggleObjectKeyForFilter(state.homeDashboardFilter.group,action.payload)
        }
      };
    // case "STORE_HOME_DASHBOARD_GROUP":
    //     return {
    //       ...state,
    //       homeDashboardFilter: {
    //         ...state.homeDashboardFilter,
    //         group : {},
    //         groupIds : toggleObjectKeyForFilter(state.homeDashboardFilter.groupIds,action.payload)
    //       }
    //   }; 
    case "REMOVE_HOME_DASHBOARD_FILTER":
        return {
          ...state,
          homeDashboardFilter: action.payload
        }; 
    case "STORE_HOME_DASHBOARD_AGENT_PRIORITY":
          return {
            ...state,
            homeDashboardFilter: {
              ...state.homeDashboardFilter,
              priority : toggleObjectKeyForFilter(state.homeDashboardFilter.priority,action.payload)
            }
          }; 
    case "STORE_DASHBOARD_SUMMARY":
          return {
            ...state,
            dashboardSummary: action.payload,
          }; 
    case "STORE_DASHBOARD_SUMMARY_GROUP":
          return {
            ...state,
            dashboardSummary: {
              ...state.dashboardSummary,
              group : action.payload
            },
          };  
    case "STORE_QUERY_PARAM":
      return {
        ...state,
        queryParam: action.payload,
      };
    case "STORE_ALL_RESPONSES":
      return {
        ...state,
        allResponses: action.payload,
      };
    case `${STORE_NEW_TICKETS}`:
      return {
        ...state,
        newTickets: action.payload,
      };
    case `${STORE_NEW_TO_ME_TICKETS}`:
      return {
        ...state,
        newToMeTickets: action.payload,
      };
    case `${STORE_PRIORITY_TICKETS}`:
      return {
        ...state,
        priorityTickets: action.payload,
      };
    default:
      return state;
  }
};

export const TicketsReducerForReport = (
  state = initialStateForReport,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case "STORE_ALL_TICKETS_FOR_REPORT":
      return {
        ...state,
        allTickets: action.payload,
      };
    case "STORE_ALL_TICKETS_FOR_REPORT_SUM":
      return {
        ...state,
        allTicketLogs: action.payload,
      };
    default:
      return state;
  }
};
export const TicketsReducerForCSV = (
  state = initialStateForReport,
  action: { type: any; payload: any }
) => {
  switch (action.type) {
    case "STORE_ALL_TICKETS_FOR_CSV":
      return {
        ...state,
        allTickets: action.payload,
      };
      case "STORE_ALL_TICKETS_FOR_CSV_UNSET":
        return {
          ...state,
          allTickets: [],
        };
    default:
      return state;
  }
};

export const AllTicketsReducer = (
  state = initialState1,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;
  switch (action.type) {
    case "STORE_ADD_UPDATE_INFO":
      return {
        ...state,
        mobileAddUpdate: action.payload,
    };
    case "STORE_SORT_INFO":
      return {
        ...state,
        mobileSort: action.payload,
    };
    case "TOGGLE_SEND_EMAIL" : 
      return {
        ...state,
        sendEmail: action.payload,
    };
    case "ENABLE_MOBILE_FILTER":
      return {
        ...state,
        mobileFilter: action.payload,
    };
    case "STORE_CONTACT_VIEW_INFO":
      return {
        ...state,
        contactInfo: action.payload,
    };
    case "STORE_POPOVER_INFO":
      return {
        ...state,
        mobileActionsPopover: action.payload,
    };
    case "TOGGLE_CSV_LOADER":
      return {
        ...state,
        loader: payload,
      };
    case "STORE_TICKETS_FOR_REPORT":
      return {
        ...state,
        allTickets: payload.map((i: ITicket) => {
          const {
            title,
            note,
            thirdPartyRefNo,
            openDateTime,
            dueDateTime,
            slaRemaining,
            ticketStatus,
            priority,
            ticketType,
            group,
            agent,
            contacts,
            contactOrganisation,
          } = i.ticketLogs;
          const tempObj: any = {
            id: i.id,
            sourceCode: i.sourceCode,
            title,
            note,
            thirdPartyRefNo,
            openDateTime,
            dueDateTime,
            slaRemaining,
            ticketStatus: ticketStatus?.displayName || "null",
            priority: priority?.name || "null",
            ticketType: ticketType?.displayName || "null",
            group: group?.name || "null",
            agent: agent?.firstName || "null",
            contacts: contacts?.name || "null",
            contactOrganisation: contactOrganisation?.name || "null",
          };
          return tempObj;
        }),
        loader: false,
      };
    case "SAVE_ALL_TICKETS":
      document.body.classList.remove("loader");
      const tempObj = JSON.parse(JSON.stringify(state));
      tempObj.newTickets = action.payload[0];
      tempObj.newToMeTickets = action.payload[1];
      tempObj.otherTickets = action.payload[2];
      tempObj.hasNewTickets = action.payload[0].length < 100 ? false : true;
      tempObj.hasNewToMeTickets = action.payload[1].length < 100 ? false : true;
      tempObj.hasOtherTickets = action.payload[2].length < 100 ? false : true;
      tempObj.pageNoNewTickets = 0;
      tempObj.pageNoNewToMeTickets = 0;
      tempObj.pageNoOtherTickets = 0;
      return tempObj;
    case "UPDATE_MOBILE_ALL_TICKETS_LIST":
      return {
        ...state,
        otherTickets : (state.otherTickets || []).map((i:ITicket)=>{
          if(i.id === action.payload.id){
            i = action.payload;
          }
          return i;
        })
      };
    case "SAVE_ALL_CALENDAR_TICKETS":
      return {
        ...state,
        calendarTickets : action?.payload
      }
    case "UPDATE_TICKET_ON_LIST":
      console.log("UPDATE_TICKET_ON_LIST", action?.payload);
      return {
        ...state,
        otherTickets: state.otherTickets.map((i: any) => {
          if (i.id === action?.payload?.id) {
            i = action.payload;
          }
          return i;
        }),
      };
    case "SAVE_OTHER_TICKETS":
      return {
        ...state,
        pageNoOtherTickets: action?.payload?.pageNo,
        otherTickets: [...state.otherTickets, ...action?.payload?.data],
        hasOtherTickets: action?.payload?.hasMore,
      };
    case "SAVE_NEW_TICKETS":
      return {
        ...state,
        pageNoNewTickets: action?.payload?.pageNo,
        newTickets: action?.payload?.data,
        hasNewTickets: action?.payload?.hasMore,
      };
    case "SAVE_NEW_TO_ME_TICKETS":
      return {
        ...state,
        pageNoNewToMeTickets: action?.payload?.pageNo,
        newToMeTickets: action?.payload?.data,
        hasNewToMeTickets: action?.payload?.hasMore,
      };
    case "DROPPED_TO_NEWTOME":
      console.log(
        "DROPPED_TO_NEWTOME",
        state,
        action.payload,
        state.newTickets
      );
      const droppedTicket: any = state.newTickets.filter(
        (x: any) => x.id === +action.payload
      )?.[0];
      if (!droppedTicket) return state;
      let mynewTickets: any = state.newToMeTickets;
      mynewTickets.unshift(droppedTicket);
      return {
        ...state,
        newToMeTickets: [...mynewTickets],
        newTickets: [
          ...state.newTickets.filter((x: any) => x.id !== droppedTicket?.id),
        ],
      };
    case "UPDATE_LIST_AFTER_QUICKUPDATE":
      return {
        ...state,
        otherTickets: state.otherTickets.map((i: any) => {
          if (i.id === action?.payload?.ticketId) {
            i = action?.payload?.ticketInfo;
          }
          return i;
        }),
      };
    case "DROPPED_TO_PRIORITY":
      return {
        ...state,
        otherTickets: state.otherTickets.map((i: any) => {
          if (i.id === +action?.payload?.ticketId) {
            i.ticketLogs = {
              ...i.ticketLogs,
              ...action?.payload?.objToReplace,
            };
          }
          return i;
        }),
      };
    case "DROPPED_TO_STATUS":
      console.log("DROPPED_TO_STATUS", state?.otherTickets, action.payload);
      //const {ticketId,objToReplace,response} = action.payload;
      return {
        ...state,
        otherTickets: state.otherTickets.map((i: any) => {
          if (i.id === +action?.payload?.ticketId) {
            i.ticketLogs = {
              ...i.ticketLogs,
              ...action?.payload?.objToReplace,
            };
          }
          return i;
        }),
      };
    case "DROPPED_TO_GROUPLIST":
      console.log("DROPPED_TO_GROUPLIST", action?.payload);
      let newBlockTickets: any = [];
      let newToMeTickets: any = [];
      let sourceBox = action?.payload?.isMyTickets;

      if (action?.payload?.objToReplace?.statusId === 1) {
        if (sourceBox === "myTickets") {
          newBlockTickets = state.newTickets;
          newToMeTickets = state.newToMeTickets.filter((i: any) => {
            const isValid = i.id === action?.payload?.ticketId;
            if (isValid) {
              i.ticketLogs = {
                ...i.ticketLogs,
                ...action?.payload?.objToReplace,
              };
              newBlockTickets.push(i);
            }
            return !isValid;
          });
        } else if (sourceBox === "newTickets") {
          newToMeTickets = state.newToMeTickets;
          newBlockTickets = state.newTickets.map((i: any) => {
            const isValid = i.id === action?.payload?.ticketId;
            if (isValid) {
              i.ticketLogs = {
                ...i.ticketLogs,
                ...action?.payload?.objToReplace,
              };
            }
            return i;
          });
        }
      } else {
        if (sourceBox === "newTickets") {
          newToMeTickets = state.newToMeTickets;
          newBlockTickets = state.newTickets.filter((i: any) => {
            const isValid = i.id === action?.payload?.ticketId;
            if (isValid) {
              i.ticketLogs = {
                ...i.ticketLogs,
                ...action?.payload?.objToReplace,
              };
              newToMeTickets.push(i);
            }
            return !isValid;
          });
        } else if (sourceBox === "myTickets") {
          newBlockTickets = state.newTickets;
          newToMeTickets = state.newToMeTickets.map((i: any) => {
            const isValid = i.id === action?.payload?.ticketId;
            if (isValid) {
              i.ticketLogs = {
                ...i.ticketLogs,
                ...action?.payload?.objToReplace,
              };
            }
            return i;
          });
        }
      }
      return {
        ...state,
        newTickets: [...newBlockTickets],
        newToMeTickets: [...newToMeTickets],
      };
    default:
      return state;
  }
};

export default TicketsReducer;
