import PhoneIcon from '@mui/icons-material/Phone'
import { Box } from '@mui/material'
import { createRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TextBoxLatest } from '../../../components/common/TextBoxLatest/TextBoxLatest'
import BasicButton from '../../../components/common/button/BasicButton'
import CustomDatePicker from '../../../components/common/date-picker/CustomDatePicker'
import CustomFormLabel from '../../../components/common/form-label/CustomFormLabel '
import { returnFormValues } from '../../../components/helpers/helperFunctions'
import { CallRequestAdd } from '../../../redux/actions/FreeTrial/FreeTrial'
import { ValidationResultS, iFormData } from '../../new-ticket/LatestTicket/Type'
import { isValidate } from '../../new-ticket/LatestTicket/Validation'
import { schemaForCallRequest } from './Schema'
import { generateErrorList } from '../../new-ticket/LatestTicket/Form'
import CustomDateTimePicker from '../../../components/common/date-picker/CustomDateTimePicker'
import moment from 'moment'
// name 
// mobileNumber
// mailId 
// availableAt 
// interestedInAccountType
function CallRequest({ setShowContact }:{setShowContact:any}) {
    const nameRef:any = createRef();
    const dispatch = useDispatch()
    const [availableAt,setAvailableAt] = useState("")
    const [error, setError] = useState<any>({});
    const onSubmit = (event: any) => {
        event.preventDefault();
        const data:iFormData = returnFormValues(event.currentTarget);
        let validationData: any = {
            name: data.name,
            mobileNumber: data.mobileNumber,
            mailId: data.mailId,
          };
  
      const validation:any = isValidate(validationData, schemaForCallRequest);
      if (validation.isValid) {
          let payload: any = {
              name: data.name,
              mailId: data.mailId,
              mobileNumber: data.mobileNumber,
              availableAt: data.availableAt
          };
        console.log("payload", payload)
        dispatch(CallRequestAdd(payload,submitCB))
      } else {
        setError(validation.errors)
        // {name:['aaa']}
      }
    }
    const submitCB = (resCode:string,response:any) => {
        console.log(resCode,response)
          try{
              if(resCode!=="1"){
                setShowContact(false)
                console.log(resCode,response)
              }
          }catch(e){}
      }
      
    return (
      <Box sx={{ width: 300 }}>
        <form onSubmit={onSubmit}>
          <TextBoxLatest
            placeholder="Please Enter"
            title={"Name"}
            ref={nameRef}
            isMandotary={true}
            errorMsg={`${error?.name}`}
            isError={error?.name ? true : false}
            name="name"
            type="text"
            onchangeHandler={(event: any) => {
              setError({ ...error, name: false });
            }}
          />
          <TextBoxLatest
            placeholder="Please Enter"
            title={"Email"}
            ref={nameRef}
            isMandotary={false}
            errorMsg={`${error?.mailId}`}
            isError={error?.mailId ? true : false}
            name="mailId"
            type="text"
            onchangeHandler={(event: any) => {
              setError({ ...error, mailId: false });
            }}
          />
          <TextBoxLatest
            placeholder="Please Enter"
            title={"Contact Number"}
            ref={nameRef}
            isMandotary={true}
            errorMsg={`${error?.mobileNumber}`}
            isError={error?.mobileNumber ? true : false}
            name="mobileNumber"
            type="number"
            onchangeHandler={(event: any) => {
              setError({ ...error, mobileNumber: false });
            }}
          />

          <CustomFormLabel
            labelName={"Best time to call"}
            isMandotary={false}
          />
          {/* <CustomDatePicker
                  value={availableAt}
                  minDate={new Date()}
                  isError={false}
                  helperText={""}
                  isDisabled={false}
                  onHandleChange={(e: any) => {
                    setAvailableAt(e)
                    //formik.setFieldValue("openDate", e);
                  }}
                  name={"availableAt"}
                  classeNames="sml-picker"
                /> */}
          <CustomDateTimePicker
            value={availableAt ? moment(availableAt) : null} // Ensure it's a moment object
            minDate={moment()} // Use moment() for minDate
            isError={false}
            helperText={""}
            isDisabled={false}
            onHandleChange={(e: any) => {
              console.log("Selected Date:", e); // Debugging
              setAvailableAt(e ? moment(e).toISOString() : ""); // Store ISO format
            }}
            name={"availableAt"}
            classeNames="sml-picker"
          />

          <Box className="pt-1 text-center">
            <BasicButton
              type={"submit"}
              isLoading={false}
              isDisabled={false}
              color="primary"
              label={"Call Me"}
              size="large"
              variant={"contained"}
              endIcon={<PhoneIcon />}
            />
          </Box>
        </form>
      </Box>
    );
}

export default CallRequest