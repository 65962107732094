const initialState = {
  Data: [],
  categoryObject: {},
  unAssignedData:[],
  showLoading: false,
  allCategoryTree:[],
  categoryList:[],
  StatusCode: null
};

const categoryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "STORE_CATEGORY_FOR_ROLES":
      return {
        ...state,
        categoryList: action.payload,
      };
    case "getCategory":
      return {
        ...state,
        Data: action.payload,
      };
      case "unassignedTicket":
      return {
        ...state,
        unAssignedData: action.payload,
      };
    case "singleCategory":
      return {
        ...state,
        Data: action.payload,
      };
    case 'REMOVE_CATEGORY_ON_STATUS_CHANGE':
      return {
          ...state,
          Data: state.Data.filter((i:{id:number})=>i.id !== action.payload),
      };
    case "storeCategoryTree": 
      return {
        ...state,
        allCategoryTree : action.payload
      }
    case "STATUS_CODE_TICKET_TYPE": 
      return {
        ...state,
        StatusCode : action.payload
      }
    case 'LOAD_MORE_CATEGORYS':
      return {
          ...state,
          Data: [...state.Data , ...action.payload],
      };
    case "updateCategory":
    case "multipalUpdateCategory":
    case "bulkDeleteItem":
    case "softDeleteCategory":
      return {
        ...state,
      };
    case "deleteCategory":
      return {
        ...state,
      };
      case "getCategoryObject":
        return {
          ...state,
          categoryObject: action.payload?.obj,
          categoryObjectById: action.payload?.objById,
        };
    case "loading":
      return {
        ...state,
        showLoading: action.payload,
      };
    default:
      return state;
  }
};

export default categoryReducer;
