import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";

export default function CustomDateTimePicker({
  value,
  onHandleChange,
  isError,
  helperText,
  name,
  isDisabled,
  maxDate,
  minDate,
  classeNames = "",
}: {
  value: any;
  onHandleChange: any;
  isError: any;
  helperText: any;
  name: any;
  isDisabled: any;
  maxDate?: any;
  minDate?: any;
  classeNames?: any;
}) {
  const [selectedDate, setSelectedDate] = useState<any>(null);

  useEffect(() => {
    if (value) {
      setSelectedDate(moment(value));
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
      <div className={classeNames}>
        <DateTimePicker
          maxDate={maxDate ? moment(maxDate) : undefined}
          minDate={minDate ? moment(minDate) : undefined}
          disabled={isDisabled}
          value={selectedDate}
          onChange={(date) => {
            console.log("Picked Date:", date); // Debugging
            setSelectedDate(date);
            onHandleChange(date ? moment(date).format("YYYY-MM-DDTHH:mm:ss") : ""); // Use ISO format
          }}
          renderInput={(params) => (
            <TextField
              name={name}
              fullWidth
              {...params}
              placeholder="DD/MM/YYYY hh:mm AM/PM"
              className="date-picker-input"
              error={isError}
              autoComplete="off"
              helperText={helperText}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  );
}
