import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import { DoLogOutAction, isLoginAction } from "../Login/Login";
import { UnknownObjectArray } from "../../../pages/new-ticket/LatestTicket/Type";
import { saveProfileImg } from "../userManagement/agents";
import LanguageData from "../../../configs/LanguageData.json"
import { getUserEditionPermissions } from "../userManagement/feature";
export const url = `${configs.BASE_URL}/Contacts`;
export const getContactsUrl = `${configs.BASE_URL}/Contacts`;
export const ContactUrlSSP = `${configs.BASE_URL}/user/Contact`;
const softDeleteUrl = `${url}/status/change`;//?ids=14&status=false
const hardDeleteUrl = `${url}/permanent/delete`;
const syncContactsUrl = `${url}/azure/sync/users`;
const importcsv = `${url}/upload/csv`;
const ContactByToken = `${configs.BASE_URL}/user/Contact/by/token/v2`;
const  resetPasswordUrl=`${url}/reset/password`
export const GET_CONTACTS = 'GET_CONTACTS';
export const ADD_CONTACTS = 'ADD_CONTACTS';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const SYNC_CONTACT = 'SYNC_CONTACT';
export const LOGGEDIN_CONTACT = 'LOGGEDIN_CONTACT';
export const fileManagementUrl = `${configs.BASE_URL_FILE_UPLOAD}/Files/`;

export interface IFileUpload {
  _FormFile:File;
  type:string;
  fileupload?: any;
}
export const storeAllContacts = (val: any) => ({
    type: GET_CONTACTS,
    payload: val,
});
export const storeMoreContacts = (val: any) => ({
  type: "LOAD_MORE_CONTACTS",
  payload: val,
});
export const removeContacts = (id: number) => ({
  type: "REMOVE_CONTACT_ON_STATUS_CHANGE",
  payload: id,
});
export const storeContactsSSP = (val: any) => ({
  type: "STORE_SSP_CONTACTS",
  payload: val,
});
export const storeLoggedinContact = (val: any) => ({
  type: LOGGEDIN_CONTACT,
  payload: val,
});
export const addNewContact = (val: any) => ({
  type: ADD_CONTACTS,
  payload: val,
});
export const storeEditContact = (val: any) => ({
  type: EDIT_CONTACT,
  payload: val,
});
export const syncContact = (val: any) => ({
  type: SYNC_CONTACT,
  payload: val,
});
export const addContactAction = (data: any, fun: any  = undefined) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(`${url}`,data);
      dispatch(addNewContact('0')); // why ? // shamsu
      // dispatch(getContacts());
      fun && fun(response)
      dispatch({
        type: ActionType.SNACKBAR, 
        payload: {
          message:`${LanguageData.SUCCESS_NEW_CONTACT}${LanguageData.TICKET_CREATED}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error:any) {
      dispatch(addNewContact('1'));// why ? // shamsu
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      fun && fun(error.response)
      return console.log(error.response);
    }
  };
};
export const GetContactimage=(id:any, callback:any)=>{
  axios.get(fileManagementUrl+id).then((response) => {
    callback(response.data.filePath)
  })
}
export const SyncContactsAction = () => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(syncContactsUrl);
      dispatch(syncContact(true))
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Active Directory Sync Completed.",
          severity: "success",
          open: true,
        },
      });
    } catch (error:any) {
      dispatch(syncContact(false))
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error?.response?.data && typeof error.response.data === 'string'?error.response.data : "Something went wrong! Try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const ImportCSV =  (
  fileObejct: IFileUpload
) => {
  return async (dispatch: any) => {
  try {
    // let localFileObj:any = fileObejct;
    const formData:any = new FormData();
    formData.append("_FormFile", fileObejct._FormFile);
    formData._FormFile = fileObejct._FormFile;
    const res = await axios.post(
      importcsv, formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(res)
    dispatch(getContacts());
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: 'Contacts imported successfully!',
        severity: "success",
        open: true,
      },
    });
  } catch (e:any) {
    console.log(e);
    console.log(e.response);
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: e.response.data && typeof e.response.data === "string" ? e.response.data : 'Something went wront, Please try again!',
        severity: "error",
        open: true,
      },
    });
    return console.log(e.response)
  }
}
};

export const getContactsForDropdown = (searchKey:string,callback:any) => {
  return async (dispatch: any) => {
    try {
      let response = await axios.get(`${url}?search=${searchKey}&page=1&count=50&isActive=true`);
      console.log("getContactsLazy-e",new Date())
      if(callback) callback(response.data.map((i:any)=>{
        const {id,isVIP, knownAs, name, email } = i;
        return {
          id,
          isVIP, 
          label : `${name},${email ? "( " + email + " )" : ""}`,
          value : id,
        }
      }));
    } catch (error:any) {
      console.log(error);
      if(callback) callback([]);
    }
  };
  
}

export const getContactsLazy = (pageNum: Number,isActive:boolean,search:string,callback:any) => {
  return async (dispatch: any) => {
    try {
      let count = 50;
      let searchVal = search ? `&search=${search}` : "";
      let response = await axios.get(`${url}?page=${pageNum}&count=${count}&isActive=${isActive}${searchVal}`);
      console.log("getContactsLazy-e",new Date())
      if(callback) callback(pageNum,response.data, response.data.length === count);
      if(pageNum === 1){
        dispatch(storeAllContacts(response.data));
      } else {
        dispatch(storeMoreContacts(response.data));
      }
    } catch (error:any) {
      console.log(error);
    }
  };
  
}

export const ContactByTokenAction = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(ContactByToken);
      // console.log('response', response)
      // dispatch(storeAllContacts(response.data));
      dispatch(isLoginAction(true)); 
      dispatch(storeLoggedinContact(response.data))
      dispatch(getUserEditionPermissions(null, true));
      //response.data.filePath
      // response.data.profilePicturePath = response.data.profilePicturePath.replaceAll("https://simplisysstoragev1.blob.core.windows.net","");
      // response.data.profilePicturePath = "https://simplisysstoragev1.blob.core.windows.net" + response.data.profilePicturePath;
      dispatch(saveProfileImg("profile",response.data.profilePicturePath));
      if(callback) callback('0', response.data);
    } catch (error:any) {
      localStorage.clear()
      dispatch(DoLogOutAction())
      console.log('response', error)
      if(callback) callback('1', error.response)
      return console.log(error);
    }
  };
};
export const getContacts = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(url);
      console.log('response', response)
      dispatch(storeAllContacts(response.data));
      if(callback) callback(['0', response.data]);
    } catch (error:any) {
      console.log('response', error)
      if(callback) callback(['1', error.response])
      return console.log(error);
    }
  };
};
export const getContactList = (arr:UnknownObjectArray=[]) =>{
  try {
    const output = [];
    for (const item of arr) {
      if(item.isActive){
        output.push({
          id : item.id,
          name : item.knownAs
        })
      }
    }
    return output;
  } catch (error) {
    console.log("ERROR")
    return []
  }
}
export const getSSPContacts = (callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios(ContactUrlSSP);
      dispatch(storeContactsSSP(getContactList(response.data)));
    } catch (error:any) {
      console.log('response', error)
      return console.log(error);
    }
  };
};
export const getContactByIdAction = (id:string, callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${url}/${id}`);
      dispatch({
        type: "STORE_CONTACTS_BY_ID",
        payload : response?.data
      })
      try{
        callback && callback('0',response.data)
      }catch(e){
        console.log('get asset by ID err','0',e)
      }
    } catch (error:any) {
      try{
        callback('1',error.response)
      }catch(e){
        console.log('get asset by ID err','0',e)
      }
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error?.response?.data && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
// // awithout string to redux, just return resposne
// export const getContactFun = async () => {
//   try {
//     const contacts = await axios.get(url);
//       console.log("api is called",tickets.data);
//       return contacts.data;
//   } catch (e) {
//       console.log(e)
//   }
// }
export const softDeleteContacts = (id:number,status:boolean,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.delete(`${softDeleteUrl}?ids=${id}&status=${status}`);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: status ? `${LanguageData.SUCCESS_CONTACT}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_CONTACT}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`,
          severity: "success",
          open: true,
        },
      });
      if(callback) callback(id)
      dispatch(removeContacts(id))
      // dispatch(getContacts());
      // ;
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};

export const hardDeleteContacts = (id:number) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.delete(`${hardDeleteUrl}/${id}`);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_CONTACT}${LanguageData.TICKET_DELETED}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(getContacts());
      ;
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};


export const editContactAction = (id: any, data: any, callBack: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.put(`${url}/${id}`,data);
      dispatch(addNewContact('0')); // why ? // shamsu
      // dispatch(getContacts());
      callBack && callBack(response)
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_CONTACT}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error:any) {
      dispatch(addNewContact('1'));// why ? // shamsu
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      callBack && callBack(error.response)
      return console.log(error.response);
    }
  };
};

export const editContacts = (id:number,request:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.put(`${url}/${id}`,request);
      ;
      console.log(`${url}/${id}`);
      dispatch(addNewContact('0'));
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_CONTACT}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};

export const UpdateContactsSsp = (request:any , callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response:any = await axios.put(`${configs.BASE_URL}/user/Contact/profile`,request);
      if (callback) callback();
      // dispatch(storeLoggedinContact(response.data))
      // dispatch(saveProfileImg("profile",response.data.profilePicturePath));
      
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_CONTACT}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const resetPasswordContactAgent = (id: any,data:any,callback:any) => {//to change logged in user password
  return async (dispatch: any) => {
    try {
      console.log('data',data)
      const response = await axios.post(`${resetPasswordUrl}`, data);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_PASSWORD}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
      dispatch(callback('success'));
    } catch (error:any) {
      if (error.response) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error?.response?.data && typeof error.response.data === "string" ? error.response.data : "Something went wrong! Try again later.",
            severity: "error",
            open: true,
          },
        });
        dispatch(callback('failure'));
        console.log('error=>', error.response.data);
      }  
    }
  };
};

export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};

export default addContactAction;
