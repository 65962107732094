import React from "react";
import { createStyles, makeStyles, withStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import InputBase from "@mui/material/InputBase";
import { Box, Typography } from "@mui/material";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Chip from "@mui/material/Chip";
import CommonStyles from "../common/CommonStyles";

import { createTheme } from "@mui/material/styles";

const theme: any = createTheme();
const BootstrapInput = withStyles(() =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 13,
      width: "100%",
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

const useStyles = makeStyles(() =>
  createStyles({
    margin: {
      marginBottom: "1rem",
      width: "100%",
      "& >div > div": {
        width: "100%",
      },
    },
    Box: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      "@media screen and (max-width:980px)": {
        display: "block",
      },
    },
    title: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
      minWidth: "150px",
      "@media screen and (max-width:980px)": {
        marginBottom: "5px",
      },
    },
    menu: {
      fontSize: "12px",
      //maxWidth:'390px',
      whiteSpace: "break-spaces",
      padding: "5px 15px",
    },
    nativeSelect: {
      "& .MuiSelect-root": {
        height: "10px",
        lineHeight: "1",
        "& option": {
          padding: "10px 4px",
          "&::before": {
            width: "1.4em",
            textAlign: "center",
            display: "inline-block",
            fontSize: 12,
          },
        },
        "@media screen and (max-width:980px)": {
          paddingTop: "6.5px",
          paddingBottom: "6.5px",
        },
      },
    },
  })
);

export default function CustomizedSelects({
  label,
  data,
  onInputChangeHandler,
  name,
  required,
  classname,
  value,
  stypleProp,
  styplePropTitle,
  noDefaultVal,
  errMsg = "",
  showErr = false,
}: any) {
  const classes = useStyles();
  const mode = CommonStyles();
  console.log("data>>>>>>>>>", data, label);
  return (
    <Box>
      <FormControl className={classes.margin}>
        <Box className={`${classes.Box}  ${classname}`}>
          <Typography
            className={`${classes.title} ${styplePropTitle} ${mode.themeColorTxt}`}
          >
            {label} {required && <span className={mode.dangerTxt}> *</span>}
          </Typography>

          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={value === "" ? 0 : value}
            name={name}
            className={`${classes.nativeSelect}  ${stypleProp}`}
            onChange={onInputChangeHandler}
            input={<BootstrapInput />}
          >
            {!noDefaultVal && (
              <MenuItem value={0} className={classes.menu}>
                <em>Please Select</em>
              </MenuItem>
            )}

            {data &&
              data
                ?.filter((val: any) => val.isActive)
                .map((value: any) => {
                  return (
                    <MenuItem
                      value={value.id === undefined ? value.name : value.id}
                      key={value.id}
                      className={`${classes.menu} two-lines-el`}
                    >
                      {value.name === undefined
                        ? value.timePlanName
                        : value.name}
                    </MenuItem>
                  );
                })}
          </Select>
          {showErr && (
            <Typography
              variant="caption"
              className={mode.errInlineMsg}
              display="block"
            >
              {errMsg}
            </Typography>
          )}
        </Box>
        {/* <Autocomplete
          className={`${classes.nativeSelect} ${stypleProp}`}
          disablePortal
          id="combo-box-demo"
          autoHighlight={true}
          //onChange={onInputChangeHandler}
          options={data?.map(
            (val: any) => val.timePlanName || val.name || val
          )}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} name={name} value={value} onChange={onInputChangeHandler} className={classes.menu}  label={label} />
          )}
        /> */}
      </FormControl>
    </Box>
  );
}
