import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import deleteIcon from "../../assest/icons/delete.svg";
import editIcon from "../../assest/icons/edit.svg";
import restoreIcon from "../../assest/icons/restore.svg";
import ViewIcon from "../../assest/icons/viewIcon.svg";
import { getCSVReportsAction, StatusChangeCSVReport } from "../../redux/actions/Reports/reports";
import { IState } from "../../redux/reducers/rootReducers";
import CommonStyles from "../common/CommonStyles";
import Card from "../common/card";
import Breadcrumbs from "../common2/Breadcrumbs";
import TopFilter from "../common2/TopFilter";
interface AllReportsProps {
  CustomReportsAccess :any;
  isSuperAdmin:boolean;
}
const AllCSVReport = (props:AllReportsProps) => {
  const {CustomReportsAccess={},isSuperAdmin=false} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = CommonStyles();
  const [records, setRecords] = useState([]);
  const [seachData, setSearchData] = useState("");
  const [listStatus, setlistStatus] = useState(true);
  const [list, setlist] = useState([]);
  const [update, setUpdate] = useState(false);



  const updateAllHandler = (value: any, id: any) => {};
  const showDataHandler = (show: any) => {
    setlistStatus(show);
  };
  const handleSearch = (e: any) => {
    setSearchData(e.target.value);
  };
  const getReportsCB = (resStatus: string, data: any) => {
    console.log("123data", resStatus, data);
    if (resStatus === "0") {
      setRecords(data);
    }
  };
  useEffect(() => {
    dispatch(getCSVReportsAction(getReportsCB));
  }, []);
  const softDelete = (id:number,isActive:boolean)=>{
    dispatch(StatusChangeCSVReport(id,isActive,StatusChangeCSVReportCB))
  }
  const StatusChangeCSVReportCB = ()=>{
    dispatch(getCSVReportsAction(getReportsCB));
  }
  return (
    <>
      <Breadcrumbs
        data={[
          {
            title: "Reports Management ",
            path: "/ReportsManagement",
          },
          {
            title: "CSV Reports ",
            path: "/all-csv-reports",
          },
        ]}
      />
     
      <Box className="pageHolder">
        <TopFilter
          path="/CSVReport"
          Data={list}
          SearchHandler={handleSearch}
          value={seachData}
          filterHandler={updateAllHandler}
          checkBoxHandler={updateAllHandler}
          checked={false}
          hideSelectAllOption={true}
          // hideActiveBtns={true}
          filterItems={showDataHandler}
          updateAllHandler={updateAllHandler}
          titleState={false}
          hideAddBtn={(isSuperAdmin || CustomReportsAccess?.Create) ? false  : true}
          pathname="/CreateReport"
          placeHolder="Search ..."
          redirectTo="/ReportsManagement"
        />
        
        <Grid container className="pt-1" spacing={1}>
          {records &&
            records.filter((i:{isActive:boolean})=>i.isActive === listStatus).map((item: any, key: number) => {
              const { isActive, icon, name, path, id } = item;
              return (
                <Grid item md={3} xs={12} key={key}>
                  <Card
                    styleProp={classes.cardStyle}
                    customHeader={
                      <Box
                        className={`${classes.row} ${classes.alignItemsCenter} ${classes.justifyBetween} ${classes.w100}`}
                      >
                        <Tooltip title={name}>
                          <Typography variant="body2" className={classes.title}>
                            {name}
                          </Typography>
                        </Tooltip>
                      </Box>
                    }
                    cardBody={
                      <Box>
                        <Typography variant="body2">
                          <b>Report ID : </b>{id}
                        </Typography>
                      </Box>
                    }
                    cardFooter={
                      <Box>
                        {(listStatus === isActive && (isSuperAdmin || CustomReportsAccess?.View)) &&(
                          <img
                            // src={editIcon}
                            src={listStatus ? deleteIcon : restoreIcon}
                            alt="Delete icon"
                            onClick={() => {
                              softDelete(id,!isActive)
                              // EditHandler(id);
                            }}
                        />)}
                      {(listStatus === isActive && (isSuperAdmin || CustomReportsAccess?.View)) &&
                        <Tooltip title="View">
                          <img
                            src={ViewIcon}
                            alt="view icon"
                            onClick={(event) => {
                                navigate(`/view-csv/${id}`);
                            }}
                          />
                        </Tooltip>
                      }
                      {(listStatus ===  isActive && (isSuperAdmin || CustomReportsAccess?.Edit)) &&
                        <Tooltip title="Edit">
                          <img
                            src={editIcon}
                            alt="view icon"
                            onClick={() => {
                              navigate(`/CSVReport/${id}`);
                            }}
                          />
                        </Tooltip>
                      }
                      </Box>
                    }
                  />
                </Grid>
              );
            })}
        </Grid>

        {records.filter((i:{isActive:boolean})=>i.isActive === listStatus).length === 0   && 
          <Typography className="text-center pt-4">No Records Found</Typography>
        }
      </Box>
    </>
  );
};

const mapStateToProps = (state:IState) => {
  return ({
    CustomReportsAccess: state?.LoginReducer?.roleAccess?.CustomReports,
    isSuperAdmin : state?.LoginReducer?.roleAccess?.isSuperAdmin
  })
};

export default connect(mapStateToProps)(AllCSVReport);