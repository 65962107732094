import axios from "axios";
import { alertMessage } from "../SelfServicePortal/ContactAid/ContactAid";
import configs from "../../../configs/config";
import { getTenantBySubDomain } from "../Login/Login";
import { getSubDomainName } from "../../../components/helpers/helperFunctions";


export const sspUrl = `${configs.BASE_URL}/user/survey`;
export const url = `${configs.BASE_URL}/common/Survey/feedback/submit`;
export const getFeedbackBySurveyUrl =`${configs.BASE_URL}/survey/feedback/by`

export const getAllFeedback = (callback:any,isSSP:boolean  =false) => {
    return async (dispatch: any) => {
      try {
        const response = await axios(`${isSSP ? sspUrl : url}`);
        response.data = response.data.map((i:any)=>{
          return {...i,rid:`#${i.id}`,value:i.name,label:i.name}
        }) 
        dispatch({
          type : "STORE_ALL_FEEDBACK",
          payload : response.data
        })
        try{callback && callback("0",response.data)}catch(e){}
      } catch (error:any) {
        callback && callback("1",error.response)
        return console.log(error.response);
      }
    };
  };
export const getFeedbackBySurvey = (id:any,callback?:any,searchVal?:any, startDate?: any, endDate?: any) => {
    return async (dispatch: any) => {
      try {
        const response = await axios(`${getFeedbackBySurveyUrl}/${id}?starDate=${startDate}&endDate=${endDate}&search=${searchVal}`);
        response.data = response.data.map((i:any)=>{
          return {...i,rid:`#${i.id}`,value:i.name,label:i.name}
        }) 
        dispatch({
          type : "STORE_ALL_FEEDBACK",
          payload : response.data
        })
        try{callback && callback("0",response.data)}catch(e){}
      } catch (error:any) {
        callback && callback("1",error.response)
        return console.log(error.response);
      }
    };
  };

export const addFeedback= (data: any, callback: any  = undefined,token:any) => {
    return async (dispatch: any) => {
        try {
            const subDomain = getSubDomainName();
            const tenantRes = await axios(`${getTenantBySubDomain}${subDomain}`);
            const response = await axios.post(`${url}`, data,{
                headers: {
                    "t" : `${token}`,
                    "tenant-code": tenantRes?.data.tenantcode
                },
            });
        dispatch(alertMessage("Thank you for your feedback! We appreciate your input and will use it to improve our service.","success"))
        try{callback && callback("0",response.data)}catch(e){}
      } catch (error:any) {
        dispatch(alertMessage(error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.","error"))
        callback && callback("1",error.response)
        return console.log(error.response);
      }
    };
};