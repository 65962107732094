import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import LanguageData from "../../../configs/LanguageData.json";
export const url = `${configs.BASE_URL}/webform`;

const getWebForm = (type:string="Ticket",callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(url);
      const list:any = []
      response.data.map((i:any)=>{
        if(i.type === type ){list.push( {...i,value:i.name,label:i.name})}
      })
      dispatch({type: "STORE_WEBFORM",payload: list})
      try{
        if(callback) callback(list);
      }catch(e:any){
        console.log('e')
      }
    } catch (error:any) {
      return console.log(error);
    }
  };
};
export const getWebFormById = (id:string|number,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${url}/${id}`);
      try {
        if(callback) {
          response.data.webformFieldList = response.data.webformFieldList.map((i:any)=>{
            return {
              ...i,
              oldId : i.id,
              id : +i?.fieldId,
            }
          })
          callback('0',response.data)
        }
      } catch(e){
        console.log(e)
      }
    } catch (error:any) {
      if(callback) callback('1',error.response)
      return console.log(error);
    }
  };
};
export const WebFormStatusChange = (data: any, ids: any, status: any, type: string) => {
  const baseMessage = type === "Survey" ? "Survey Template" : "Web Form";
  const idMsg = ids.length === undefined
    ? `${LanguageData.SUCCESS_MSG} ${baseMessage} ${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}`
    : `${LanguageData.SUCCESS_MSG} ${baseMessage} ${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`;
  const statusMsg = status
    ? `${LanguageData.SUCCESS_MSG} ${baseMessage} ${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}`
    : `${LanguageData.SUCCESS_MSG} ${baseMessage} ${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`;

  return function (dispatch: any) {
    axios.delete(`${url}/status/change?ids=${ids}&status=${status}`, data).then((resp) => {
      dispatch(getWebForm(type === "Survey" ? "Survey" : "Ticket"));
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: ids.length === undefined ? idMsg : statusMsg,
          severity: "success",
          open: true,
        },
      });
    });
  };
};

export const addWebForm = (request:any,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const baseMessage = request.type === "Survey" ? "Survey Template" : "Web Form";
      // console.log('baseMessagebaseMessage',baseMessage)
      const response = await axios.post(url,request);

      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message:`${LanguageData.SUCCESS_MSG} ${baseMessage} ${LanguageData.TICKET_CREATED}`,
          severity: "success",
          open: true,
        },
      });
      if(callback) callback('0',response)
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response &&  typeof error.response.data === 'string' ? error.response.data : "Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error);
    }
  };
};
export const editWebFormById = (request:any,callback?:any) => {
  return async (dispatch: any) => {
    const baseMessage = request.type === "Survey" ? "Survey Template" : "Web Form";
    try {
      const response = await axios.put(`${url}/${request.id}`,request);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: { 
          message: `${LanguageData.SUCCESS_MSG} ${baseMessage}  ${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
      if(callback) callback('0',response)
    } catch (error:any) {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error);
    }
  };
};
export const softDeleteWebForm = (id: any, status: any, type: string) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${url}/status/change?ids=${id}&status=${status}`);
      dispatch(getWebForm(type === "Survey" ? "Survey" : "Ticket"));
      
      const baseMessage = type === "Survey" ? "Survey Template" : "Web Form";
      const message = status 
        ? `${LanguageData.SUCCESS_MSG} ${baseMessage} ${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` 
        : `${LanguageData.SUCCESS_MSG} ${baseMessage} ${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`;

      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message,
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export default getWebForm;