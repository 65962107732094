import React, { useState } from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";
import "react-pivottable/pivottable.css";
import { CSVLink } from "react-csv";
import TableRenderers, {
  BuildInRenderersNames,
} from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Button } from "@mui/material";
import CommonStyles from "../common/CommonStyles";
import CustomModalDialog from "../common/customDialog";
import InputBox from "../common2/InputBox";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../redux/reducers/rootReducers";
import {
  getPivotTableActionById,
  postPivotTableAction,
  putPivotTableAction,
} from "../../redux/actions/Reports/reports";
import { useNavigate, useParams } from "react-router-dom";
import { InfoSharp } from "@mui/icons-material";
import { storeFiltersValue } from "../../redux/actions/TicketsManagement/GetTickets";
import moment from "moment";
import { getCategory } from "../../redux/actions/SystemManagement/AddCategory";
import {
  ConvertZ,
  formatTimeWithDays,
  timeSpanToReadbleFormatNew,
} from "../helpers/helperFunctions";
import { getAssetsAction } from "../../redux/actions/AssetsManagement/Assets";
import defaultRenderers from "react-pivottable/TableRenderers";

// Create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

export const MemoizedComponent: React.FC<any> = ({ type, children }) => {
  return React.useMemo(() => {
    return children;
  }, [type]);
};

function PivotTable({
  data,
  onClick,
  state,
  setState,
  AllTicketsLogs,
}: {
  data: any;
  onClick: any;
  state: any;
  setState: any;
  AllTicketsLogs?: any;
}) {
  const classes = CommonStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param: any = useParams();
  const [csvData, setCSVData] = useState([]);
  const [transformData, setTransformData] = useState([]);
  const [tableKey, setTableKey] = useState(0); // Add this state
  const [open, setOpen] = useState(false); // Add this state
  const [title, setTitle] = useState(""); // Add this state

  const tempCategory = useSelector(
    (state: IState) => state?.categoryReducer?.categoryObject
  );

  const AssetsList = useSelector(
    (state: IState) => state?.AssetsReducer?.AllAssets
  );

  const modifyDataForPivotTableForSum = (data: any) => {
    console.log("modifyDataForPivotTableForSum", data);
    const dataArray: any = [];
    data?.map((item: any, index: number) => {
      dataArray.push({
        ID: item?.ticketId,
        ActionBy: item?.user ? item?.user?.name : "Tickets without agent",
        Effort: item?.effortInMinutes,
        Organisation: item?.contacts?.contactOrganisation?.name
          ? item?.contacts?.contactOrganisation?.name
          : "",
        Division: item?.contacts?.division?.name
          ? item?.contacts?.division?.name
          : "",
        Department: item?.contacts?.department?.name
          ? item?.contacts?.department?.name
          : "",
      });
    });
    return dataArray;
  };
  const modifyDataForPivotTableForCount = (data: any) => {
    const dataArray: any = [];
    data?.map((item: any, index: number) => {
      dataArray.push({
        ID: item.id,
        Owner: item?.ticketLogs?.agent
          ? item?.ticketLogs?.agent?.firstName +
            "" +
            item?.ticketLogs?.agent?.lastName
          : "Tickets without an agent",
        UpdatedBy: item?.ticketLogs?.user
          ? item?.ticketLogs?.user?.name
          : "Tickets without group",

        UpdatedDateTime: item?.ticketLogs?.createdDateTime
          ? ConvertZ(item?.ticketLogs?.createdDateTime)
          : "Tickets without group",

        CreatedDateTime: item?.ticketLogs?.openDateTime
          ? ConvertZ(item?.ticketLogs?.openDateTime)
          : "Tickets without group",

        SLA:
          item?.ticketLogs?.slaRemainingInTicks <= 0
            ? "Out of SLA"
            : "Within SLA",
        Contacts: item?.ticketLogs?.contacts?.name
          ? item?.ticketLogs?.contacts?.name
          : "Tickets without a contact",

        ContactOrganisation: item?.ticketLogs?.contacts?.contactOrganisation?.name
          ? item?.ticketLogs?.contacts?.contactOrganisation?.name
          : "Tickets without an Organisation",

          
        ThirdpartyOrganisation: item?.ticketLogs?.contactOrganisation?.name
          ? item?.ticketLogs?.contactOrganisation?.name
          : "Tickets without an Organisation",

        Division: item?.ticketLogs?.contacts?.division?.name
          ? item?.ticketLogs?.contacts?.division?.name
          : "Tickets without a division",
        Department: item?.ticketLogs?.contacts?.department?.name
          ? item?.ticketLogs?.contacts?.department?.name
          : "Tickets without a department",
        TicketType: item?.ticketLogs?.ticketType?.name
          ? item?.ticketLogs?.ticketType?.name
          : "Tickets without a ticket type",
        Source: item?.sourceCode
          ? item?.sourceCode
          : "Tickets without a source",
        Group: item?.ticketLogs?.group?.name
          ? item?.ticketLogs?.group?.name
          : "Tickets without a group",
        Priority: item?.ticketLogs?.priority?.name
          ? item?.ticketLogs?.priority?.name
          : "Tickets without a priority",
        Status: item?.ticketLogs?.ticketStatus?.displayName
          ? item?.ticketLogs?.ticketStatus?.displayName
          : "Tickets without a status",
        Category: item?.ticketLogs?.category?.name
          ? item?.ticketLogs?.category?.name
          : "Tickets without a category",
        OutComeCategory: item?.ticketLogs?.outComeCategory?.name
          ? item?.ticketLogs?.outComeCategory?.name
          : "Tickets without a OutCome Category",
      });
    });
    return dataArray;
  };

  const generateTotalEffortForReport = (timeString: any) => {
    if (timeString?.includes(".")) {
      const [numberBeforeDot, restOfTime] = timeString.split(".");

      const hoursFromDays = parseInt(numberBeforeDot) * 24;

      const [hours, minutes] = restOfTime.split(":").slice(0, 2);

      console.log(
        "hoursFromDays",
        hoursFromDays,
        hours,
        hoursFromDays + parseInt(hours, 10)
      );

      let totalHour = hoursFromDays + parseInt(hours, 10);

      const formattedTime = `${totalHour}.${minutes}`;

      return formattedTime;
    } else {
      const [hours, minutes] = timeString.split(":").slice(0, 2);

      const formattedTime = `${hours}.${minutes}`;

      return formattedTime;
    }
  };

  React.useEffect(() => {
    // console.log("aggregatorName", state, param.id,data);
    if (state.aggregatorName == "Count") {
      setTransformData(modifyDataForPivotTableForCount(data));
    } else if (state.aggregatorName == "Sum") {
      setTransformData(modifyDataForPivotTableForSum(AllTicketsLogs));
    } else {
      if (data.length > 0) {
        setTransformData(modifyDataForPivotTableForCount(data));
      } else {
        setTransformData([]);
      }
    }
  }, [data, tempCategory, state, AllTicketsLogs]);

  const onChangeInputHandler = (e: any) => {
    console.log("e.target.value", e.target.value);
    const { value, name } = e.target;
    setTitle(value);
  };
  const filteredTableRenderers: any = Object.keys(defaultRenderers)
    .filter((rendererName: any) => rendererName !== "Exportable TSV")
    .reduce((filtered: any, rendererName: any) => {
      filtered[rendererName] =
        defaultRenderers[rendererName as BuildInRenderersNames];
      return filtered;
    }, {} as any);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <Button
          className={`${classes.btn} ${classes.btnSubmit}`}
          variant="contained"
          onClick={onClick}
        >
          {param.id ? "Update" : "Save"}
        </Button>
      </div>

      <CustomModalDialog
        title="Save"
        id="upgradeModalPopup"
        parentClasses="sml-popup"
        fullScreen={true}
        isOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        headerContent={<>Save</>}
        bodyContent={
          <>
            <MemoizedComponent type={title}>
              <InputBox
                title="Report Title"
                classname="h-inputs p-0 reportInput"
                type="text"
                placeholder="Please Enter"
                styleProp={classes.BoxTitle}
                inputStyle={classes.inputStyle}
                name="name"
                onChange={onChangeInputHandler}
                value={title}
              />
            </MemoizedComponent>
            <Button
              className={`${classes.btn} ${classes.btnSubmit}`}
              variant="contained"
              onClick={onClick}
            >
              Save
            </Button>
          </>
        }
      />
      <div id="pivot-table">
        <PivotTableUI
          data={transformData}
          onChange={(s: any) => {
            delete s.data;
            console.log("s?.aggregatorName", s);
            setState(s);
            // if (s?.aggregatorName == "Sum") {
            //   setTransformData(modifyDataForPivotTableForSum(AllTicketsLogs));
            // } else if (s?.aggregatorName == "Count") {
            //   setTransformData(modifyDataForPivotTableForCount(data));
            // }
          }}
          hiddenFromAggregators={["Table", "Sum", "Count Unique Values"]}
          filterValue={state.filterValue}
          renderers={{ ...filteredTableRenderers, ...PlotlyRenderers }}
          {...state}
        />
      </div>
    </div>
  );
}

export default PivotTable;