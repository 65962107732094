import { useSelector } from "react-redux";
import { IState } from "../../../../../redux/reducers/rootReducers";
import { Box, Typography } from "@mui/material";
import CommonStyles from "../../../../common/CommonStyles";
import { Suspense, lazy, memo, useEffect, useMemo, useState } from "react";
import { PrioritySkeletonLoader } from "./widgets/DashboardSkeletonLoader";
import StatusColumn from "./widgets/StatusColumn";
//import { StatusColumn } from "./widgets/StatusColumn";
// const StatusColumn = lazy(() => import("./widgets/StatusColumn"));

export const TicketStatusView = memo(() => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    console.log("innerWidth::", window.innerWidth);
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const AllStatuses = useSelector(
    (state: IState) => state?.TicketsReducer?.allResponses?.ticketStatus
  );
  const classes = CommonStyles();
  const shrinkVal: any = useSelector(
    (state: IState) => state?.LayoutTicketManagementReducer?.columnShrinked
  );
  const StatusesToIgnore = ["New", "NewtoMe", "Closed", "Delete"];
  const [statusList, setStatusList] = useState([]);
  useEffect(() => {
    if (AllStatuses?.length && AllStatuses?.length > 0) {
      setStatusList(
        AllStatuses.filter((i: any) => !StatusesToIgnore.includes(i.name))
      );
    }
  }, [AllStatuses?.length]);

  const subtractionValues: Record<number, number> = {
    7: 17,
    6: 19,
    5: 23,
    4: 28,
    3: 37,
    2: 56,
    1: 110,
  };

  const getDynamicWidth = (ticketStatusCount:number) => {
    console.log("TKT STATUS COUNT:::",ticketStatusCount)

    return window.innerWidth >= 1550
      ? `calc(calc(100vw / ${ticketStatusCount >= 7 ? 7 : ticketStatusCount}) - ${subtractionValues[ticketStatusCount >= 7 ? 7 : ticketStatusCount]}px)`
      : `calc(calc(100vw / ${ticketStatusCount >= 6 ? 6 : ticketStatusCount}) - ${subtractionValues[ticketStatusCount >= 6 ? 6 : ticketStatusCount]}px)`;
  };
  const layout = useMemo(
    () => (
      <Box
        className={`p-5p`}
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflowX: "visible",
          overflowY: "visible",
          whiteSpace: "nowrap",
          maxWidth: "100%",
          maxHeight: "50%",
        }}
      >
        {statusList.length > 0 && (
          <Box className={`${classes.row} pt-1 px-2`}>
            {statusList.map((i: any, index: number) => {
              return (
                <Box
                  style={{
                    width: getDynamicWidth(statusList.length),
                  }}
                  // sx={{
                  //   width: `calc(calc(100vw / 7) - 25px)`,
                  // }}
                  key={index}
                  className={`${
                    shrinkVal ? "col shrinked-cardCol" : "col-4"
                  } trans-5s pr-10 position-relative`}
                >
                  <Typography
                    variant="body2"
                    className="text-center makeHeaderSticky"
                    // sx={{
                    //   position: "sticky",
                    //   top: 0,
                    //   background: "white", // Ensure it's visible when sticky
                    //   zIndex: 10, // Keeps it above other content
                    //   padding: "8px 0", // Adds spacing
                    //   backgroundColor: "pink",
                    // }}
                  >
                    {i?.displayName}
                  </Typography>
                  {/* <Typography variant="body2" className="text-center">
                    {i?.displayName}
                  </Typography> */}
                  <StatusColumn columnId={i.id} columnName={i.name} />
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    ),
    [statusList, shrinkVal, classes]
  );
  return (
    <>
      {layout}
      {/* <Suspense fallback={<><PrioritySkeletonLoader /></>}></Suspense> */}
    </>
  );
});
export default TicketStatusView;
